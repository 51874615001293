@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.imageContainer
  position: relative
  content-visibility: auto
  contain-intrinsic-width: 150px
  contain-intrinsic-height: 100px

  &[data-rest]
    &::before
      content: '+' attr(data-rest)
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: flex
      justify-content: center
      align-items: center
      font-size: 36px
      line-height: 32px
      color: white
      z-index: 1
      pointer-events: none
      padding-right: 10px
      background: rgba(0, 0, 0, 0.40)
      border-radius: 12px

  &.playable
    &::after
      content: url(/img/svg/__imported__/i-icon--play.svg)
      filter: invert(100%)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1
