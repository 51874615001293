@use '~_css/ds-2023/fonts' as fonts

.header
  display: flex
  flex-direction: column
  gap: 2px
  padding: 16px 16px 24px 12px

.headerContent
  display: flex
  align-items: flex-start
  gap: 12px
  justify-content: space-between

.close
  all: initial
  cursor: pointer

.title
  @include fonts.font-h4
