@use '~_css/ds-2023/fonts' as fonts

.LegalInfo
  display: flex
  flex-direction: column
  gap: 44px
  max-width: 1001px

  .list
    display: flex
    flex-direction: column
    gap: 16px

    .header
      @include fonts.font-h1

    .helpText
      @include fonts.font-l