@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/mixins' as mixins

.layoutHeader
  position: relative
  display: flex
  gap: 4px 16px
  max-width: var(--page-max-size-outer)

  width: clamp(var(--page-min-size-outer), 100vw - var(--scrollbar-width), var(--page-max-size-outer))
  box-sizing: border-box

  padding-top: 4px
  padding-bottom: 4px
  padding-left: var(--page-side-paddings)
  padding-right: var(--page-side-paddings)
  margin-left: auto
  margin-right: auto

  border-top-left-radius: 16px
  border-top-right-radius: 16px

.menu
  display: flex
  flex-direction: column
  height: 72px

.actions
  display: flex
  align-items: center
  gap: 4px
  justify-content: flex-end
  width: 204px
  flex-shrink: 0
  margin-left: auto

.profileDropdown
  position: absolute
  top: 4px
  z-index: 100

  @include mixins.on-narrow
    right: 8px

  @include mixins.on-normal-wide
    right: 12px

.headerButton.headerButton.headerButton
  @include fonts.font-s
  padding: 15px 16px