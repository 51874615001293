@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.dropdownMenuItem
  @include buttons.default

  padding: 12px 16px
  border-radius: 22px
  text-align: left

  &:hover:not(:disabled)
    @include buttons.shadow

  &:disabled,
  &.disabled
    @include buttons.shadow-disabled
    opacity: 0.4
    cursor: not-allowed
    color: unset
