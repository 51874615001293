@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.movieSession
  @include buttons.default
  @include buttons.outline

  position: relative
  display: flex
  height: 64px
  width: 95px
  border-radius: 12px
  padding: 8px

  &:hover
    @include buttons.outline-hover

  &:disabled
    @include buttons.outline-disabled
    cursor: not-allowed
    opacity: 0.4

.data
  flex-shrink: 0
  display: flex
  flex-direction: column
  align-items: flex-start
  height: 100%

.status
  @include fonts.font-s
  color: var(--color-grey-59)

.time
  @include fonts.font-xl
  margin-top: auto

.badges
  position: absolute
  right: -2px
  top: -6px
  display: flex
  gap: 2px
