@use '~_css/ds-2023/mixins' as mixins

.carouselSlider
  scroll-snap-type: x mandatory

  @include mixins.on-narrow-normal
    padding: 0px var(--page-side-paddings)

  @include mixins.on-wide
    padding: 0px calc(var(--page-side-paddings) * 3)
