@use '~_css/ds-2023/fonts' as fonts

.badge
  @include fonts.font-s-caps
  color: var(--color-grey-45)

.name
  @include fonts.font-h3

.meta
  @include fonts.font-s
  color: var(--color-grey-45)

.htmlBlock
  @include fonts.font-s
  color: var(--color-grey-45)
