@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.listCityButton
  @include buttons.default
  @include fonts.font-m
  padding: 0
  transition: color 0.2s ease-in-out

  &:hover
    color: var(--color-red)
