.label
  position: relative
  display: flex
  align-items: center
  box-sizing: border-box
  content-visibility: auto
  contain-intrinsic-height: 24px

.input
  position: absolute
  width: 100%
  height: 100%
  opacity: 0
  z-index: 1

  &:checked
    & ~ .marker
      background-color: var(--color-red)
      border-color: var(--color-red)

  &:not(:checked)
    & ~ .marker
      background-color: transparent

  &:disabled
    cursor: not-allowed

    & ~ .marker
      opacity: 0.5

    & ~ .text
      opacity: 0.40

  &:not(:disabled)
    cursor: pointer

    &:hover
      & ~ .marker
        border-color: var(--color-red)

      & ~ .text
        color: var(--color-red)

.text
  transition: 0.2s
  margin-left: 10px

.text[data-badge]::after
  color: currentColor
  content: ' (' attr(data-badge) ')'

.marker
  display: flex
  flex-shrink: 0
  justify-content: center
  align-items: center
  box-sizing: border-box
  border: 1px solid #D9D9D9
  border-radius: 50%
  transition: 0.2s
  width: 24px
  height: 24px

  &::after
    content: ''
    height: 9px
    width: 9px
    background-color: white
    border-radius: 50%

