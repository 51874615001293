@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerSubscriptionForm
  grid-area: subscription
  display: flex
  flex-direction: column
  justify-content: flex-end
  gap: 16px

.text
  @include fonts.font-m
  white-space: pre-wrap

.form
  display: flex

  @include mixins.on-narrow
    flex-direction: column
    gap: 8px

.input
  padding: 18px 28px
  border: none

  &:not(&:disabled)
    &:hover,
    &:focus
      padding: 18px 28px
      border: none

.link
  color: var(--color-grey-45)
