@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerApp
  grid-area: app

.text
  margin-bottom: 20px

  @include mixins.on-narrow
    @include fonts.font-h5

  @include mixins.on-normal-wide
    @include fonts.font-h3

.links
  display: flex
  gap: 8px

  @include mixins.on-narrow
    display: none

.link
  & > img
    display: block
