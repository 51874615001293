@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.listButton
  @include buttons.default
  @include fonts.font-m
  padding: 0
  transition: color 0.2s ease-in-out
  width: 100%
  text-align: start

  &:hover
    color: var(--color-red)

.input
  padding: 14px 16px
  height: 56px
  width: 100%

.grey
  color: var(--color-grey-59)

.h4
  @include fonts.font-h4

.wrap
  position: relative

.chevron
  position: absolute
  right: 16px
  top: 18px