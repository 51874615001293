.block
  displa: block

.flex
  display: flex

  &.column
    flex-direction: column

.grid
  display: grid

  &.column
    grid-auto-flow: column

.fitWidth_page-content
  max-width: calc(var(--layout-content-width) + var(--layout-sidebar-margin-left) + var(--layout-sidebar-width) - ((var(--layout-sidebar-margin-left) + var(--layout-sidebar-width)) * var(--cascade-is-sidebar, 0)) - (var(--cascade-is-page-section-padding, 0) * 16px * 2))

.justify_space-around
  justify-content: space-around

.justify_space-between
  justify-content: space-between

.justify_space-evenly
  justify-content: space-evenly

.justify_start
  justify-content: flex-start

.justify_center
  justify-content: center

.justify_end
  justify-content: flex-end

.justify_stretch
  justify-content: stretch

.align_start
  align-items: flex-start

.align_center
  align-items: center

.align_end
  align-items: flex-end

.align_stretch
  align-items: stretch

.hideIfEmpty:empty
  display: none
