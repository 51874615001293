@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.okkoPromotionBanner
  display: flex
  height: 130px
  background-color: var(--color-grey-95)
  border-radius: 16px
  box-sizing: border-box
  justify-content: space-between
  position: relative
  transition: 0.2s

  &:hover
    background-color: var(--color-grey-92)

  @include mixins.on-narrow-normal
    padding: 32px 40px 32px 32px

  @include mixins.on-wide
    padding: 32px 70px 32px 60px

.badge
  position: absolute
  left: 8px
  top: 8px
  padding: 5px 10px

.shit
  overflow: hidden
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  border-radius: inherit
  pointer-events: none

  &::before
    content: ""
    position: absolute
    bottom: -110px
    background-color: #821EF6
    border-radius: 50%
    width: 270px
    height: 270px
    filter: blur(42px)
    transform: translate(50%, 50%)
    opacity: 0.3

  @include mixins.on-narrow-normal
    &::before
      right: 330px

  @include mixins.on-wide
    &::before
      right: 480px

.texts
  display: flex
  flex-direction: column
  gap: 8px
  margin-top: 6px

.text1
  @include mixins.on-narrow-normal
    @include fonts.font-h2

  @include mixins.on-wide
    @include fonts.font-h1

.text2
  color: var(--color-grey-40)

  @include mixins.on-narrow-normal
    @include fonts.font-m

  @include mixins.on-wide
    @include fonts.font-ml

.image
  position: absolute
  bottom: 0
  width: 273px
  pointer-events: none

  @include mixins.on-narrow-normal
    left: 444px

  @include mixins.on-wide
    left: 609px

.button
  @include mixins.on-wide
    width: 204px
