@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.modalFooter
  display: flex
  position: absolute
  bottom: 0
  left: 0
  right: 0
  padding: 24px 12px 16px
  gap: 8px
