@use '~_css/ds-2023/buttons' as buttons
@use '~_css/ds-2023/mixins' as mixins

$shadow-top: 0px 20px 20px -20px var(--color-black-15)
$shadow-bottom: 0px -20px 20px -20px var(--color-black-15)
$shadow-left: 20px 0px 20px -20px var(--color-black-15)
$shadow-right: -20px 0px 20px -20px var(--color-black-15)

.scheduleMapFilter
  position: relative
  width: 100%
  height: 100%
  --sidebar-width: 438px

.sidebar
  position: absolute
  top: 0
  bottom: 0
  display: flex
  flex-direction: column
  padding: 16px 0px 0px
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out

  .title
    padding: 20px 42px 0px 20px
    border-radius: 16px 16px 0px 0px
    background-color: white
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)

  .content
    @include mixins.no-scrollbar()
    overflow-x: hidden
    overflow-y: scroll
    padding: 0px 20px 20px
    margin: 0px -20px -20px
    width: 100%
    height: 100%

    .items
      background-color: white
      padding: 16px 8px 0px
      margin-bottom: 16px
      border-radius: 0px 0px 24px 24px
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
      min-height: calc(100% - 32px)
      display: flex
      flex-direction: column

      .item
        border-radius: 16px
        transition: background-color 0.2s ease-in-out
        padding: 20px 12px
        cursor: pointer

        &:hover
          background-color: var(--color-grey-95)

      .skeletonItem
        & + *
          margin-top: 20px

  &.open
    left: 16px

  &:not(.open)
    display: block
    left: calc((var(--sidebar-width) + 16px) * -1 )

  &.detailedOpen
    width: 400px
    left: 35px

  &:not(.detailedOpen)
    width: var(--sidebar-width)

.bar
  position: absolute
  top: 32px
  transition: left 0.3s ease-in-out
  display: flex
  align-items: flex-start
  gap: 16px
  right: 120px

  &.open
    left: calc(16px + var(--sidebar-width))

  &:not(.open)
    left: 16px

  &.detailedOpen
    left: calc(16px + var(--sidebar-width) - 52px)

.sidebarButton
  @include buttons.default
  @include buttons.outline
  background-color: white
  padding: 6px 12px
  transition: border-radius 0.2s ease-in-out

  &:hover
    @include buttons.outline-hover

  &.open
    border-radius: 0px 28px 28px 0px

  &:not(.open)
    border-radius: 28px

.closeButtonContainer
  position: absolute
  top: 16px
  right: 32px

.detailedSidebar
  position: absolute
  z-index: 1
  left: 16px
  bottom: 0
  transition: top 0.3s ease-in-out
  width: 438px
  background-color: white
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
  border-radius: 16px 16px 0px 0px
  overflow: hidden

  &.open
    top: 28px

  &:not(.open)
    top: 100vh
