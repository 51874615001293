@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.fullSearchPopupEmptyState
  @include fonts.font-h4

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex-grow: 1
  gap: 40px
