@use '~_css/ds-2023/mixins' as mixins

.container
  position: relative
  height: min-content

.content
  @include mixins.no-scrollbar
  overflow-x: hidden
  overflow-y: auto

  &:before,
  &:after
    position: absolute
    right: 0
    left: 0
    z-index: 1
    height: 24px
    content: ''
    transition: 0.1s
    pointer-events: none

  &:not(.shadowTop)
    &::before
      opacity: 0

  &:not(.shadowBottom)
    &::after
      opacity: 0

  &:before
    top: 0
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0) 100%)

  &:after
    bottom: 0
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.5) 90%, rgba(255, 255, 255, 0) 100%)
