@use '~_css/ds-2023/fonts' as fonts

$headerCityMaxWidth: max(100px, calc(100vw - 12px - 12px - 42px - 12px - 16px - 178px))

.commonLogo
  display: flex
  align-items: center
  gap: 12px

.logo_city
  display: flex
  flex-direction: column

.city
  @include fonts.font-s-caps
  position: relative
  max-width: $headerCityMaxWidth
  overflow: hidden
  margin-top: 4px
  align-self: flex-start
  white-space: nowrap
  text-overflow: ellipsis

  &::after
    content: ''
    position: absolute
    left: -8px
    right: -8px
    top: -8px
    bottom: -8px
