@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.other
  @include fonts.font-m
  margin-left: var(--page-side-paddings)
  margin-right: var(--page-side-paddings)

  &:not(:first-child:last-child)
    &::before
      content: 'Также:'
      display: block
      color: var(--color-grey-59)
      margin-bottom: 4px
