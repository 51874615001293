.pinControl
  position: relative
  display: flex
  align-items: center
  height: calc(var(--pin-size) * 1px)
  --pin-size: 24
  --start-end-diff: calc(var(--end) - var(--start))
  --start-min-diff: calc(var(--min) - var(--start))
  --end-max-diff: calc(var(--end) - var(--max))

.line
  position: absolute
  right: calc(var(--pin-size) * 0.5px)
  left: calc(var(--pin-size) * 0.5px)
  height: 4px
  background-color: var(--color-grey-70-40)
  border-radius: 2px

  .highlighted
    position: absolute
    right: calc(var(--end-max-diff) / var(--start-end-diff) * 100%)
    left: calc(var(--start-min-diff) / var(--start-end-diff) * 100%)
    height: 100%
    background-color: var(--color-red)
    content: ''
    border-radius: 2px

.inputs
  position: relative
  margin: 0 calc(var(--pin-size) * 0.5px)
  width: 100%
  height: 100%

.input
  position: absolute
  width: 100%
  height: calc(var(--pin-size) * 1px)
  background-color: transparent
  pointer-events: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

  &::-webkit-slider-runnable-track
    height: 0
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

  &::-webkit-slider-thumb
    width: calc(var(--pin-size) * 1px)
    height: calc(var(--pin-size) * 1px)
    border-radius: 50%
    background-color: var(--color-red)
    transform: translateY(-50%)
    pointer-events: auto
    appearance: none
    -webkit-appearance: none
    -moz-appearance: none
