@use '~_css/ds-2023/buttons' as buttons

.restrictText
  position: relative

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical

  .showMore
    display: none

  @supports (-webkit-line-clamp: 1)
    .showMore
      @include buttons.outline
      position: absolute
      bottom: 0
      left: 0

      box-sizing: border-box
      text-align: center
      cursor: pointer
      width: 120px
      height: 40px
      border-radius: 30px
      display: flex
      align-items: center
      justify-content: center
      transition: box-shadow 0.2s

      &:hover
        @include buttons.outline-hover

    .fader
      -webkit-mask-position-y: bottom
      mask-position-y: bottom
      -webkit-mask-repeat: no-repeat
      mask-repeat: no-repeat
      -webkit-mask-position-x: right
      mask-position-x: right

      mask-image: linear-gradient(to top, transparent 0%, transparent 3em, rgb(0, 0, 0) 6em, rgb(0, 0, 0) 100%)

  @supports not (-webkit-line-clamp: 1)
    .fader
      mask-image: none !important
      -webkit-mask-image: none !important
