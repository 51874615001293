@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.reviewBodyRestrictedContainer
  --w: calc(100vw - var(--page-side-paddings) * 2)
  max-height: calc(((var(--width, var(--w)) - 32px) / ((320 - 16) / (336 - 16))) - 14px - 60px - 12px - 18px - 12px - 32px)
  overflow: hidden
  flex-grow: 1

  &.restricted
    mask-image: linear-gradient(0deg,#0000 0,#000 43px,#000)
