@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

$inside-paddings: 24px 32px

.list
  display: flex
  flex-direction: column
  gap: 24px
  counter-reset: certDescCounter
  margin: 0 15%
  @include mixins.on-narrow
    margin: 0

.framed
  padding: $inside-paddings
  background-color: var(--color-grey-95)
  border-radius: 32px

.listItem
  display: flex
  counter-increment: certDescCounter
  &::before
    content: counter(certDescCounter)
    min-width: calc(156px - 32px * 2)
    @include fonts.font-n4
    @extend .framed

.content
  display: flex
  flex-direction: column
  flex-basis: 100%
  @extend .framed

.appInfo
  @extend .content
  flex-direction: row
  gap: 28px
  padding-bottom: 0
  & > img
    position: relative
    margin-top: -49px
    bottom: 0
    top: 0
    aspect-ratio: 1
    max-height: 190px
    padding-right: 24px
    width: 190px

.appInfoColumn
  display: flex
  flex-direction: column
  gap: 28px
  padding-bottom: 28px

.conditionsList
  display: flex
  flex-direction: column
  gap: 24px
  max-width: 614px
  padding-right: 85px
  list-style-type: disc
  margin-left: 19px
  & > li
    list-style-position: outside
    padding: 0

.impressions
  @extend .content
  gap: 0
  padding-bottom: 0
  @include fonts.font-h2
  & > img
    margin-top: -8px
    width: 100%

.links
  display: flex
  gap: 8px

.link
  & > img
    display: block

.usageLink
  color: var(--color-grey-45)
  margin-top: 44px
  @include fonts.font-m
  text-decoration: underline
