@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.quickSearchItem
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px 14px 10px 16px
  gap: 24px
  transition: background-color 0.2s, box-shadow 0.2s
  border-radius: 16px

  &:hover
    @include buttons.shadow
    @include buttons.shadow-hover
