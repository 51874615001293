@keyframes fadeIn
  from
    opacity: 0
    transform: scale(0.9)

  to
    opacity: 1
    transform: scale(1)

.container
  position: absolute
  z-index: 1100
  opacity: 0
  animation: fadeIn 0.2s forwards
  animation-delay: 0.1s
  will-change: transform
