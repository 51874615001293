@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.placeItemOther
  display: flex
  flex-direction: column
  gap: 2px

.placeItemOtherItem
  @include fonts.font-s
  display: flex
  gap: 10

.date
  color: var(--color-grey-59)
  min-width: 100px
