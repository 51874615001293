@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.dropdownMenu
  display: flex
  flex-direction: column
  background-color: white
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
  border-radius: 24px
  width: 280px
  box-sizing: border-box
