@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.squareItemName
  @include fonts.font-m
  overflow-wrap: anywhere

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
