@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.button
  @include buttons.default

  display: flex
  align-items: center
  justify-content: center

  border-radius: 34px

  &.extended
    width: 100%

  &.nowrap
    white-space: nowrap

  &:not(.disabled):not(:disabled)
    cursor: pointer

  &:disabled,
  &.disabled
    cursor: not-allowed
    opacity: 0.4

  &.outline
    @include buttons.outline

    @media (hover: hover)
      &:hover
        @include buttons.outline-hover

    &:disabled,
    &.disabled
      @include buttons.outline-disabled

  &.black
    @include buttons.black

    @media (hover: hover)
      &:hover
        @include buttons.black-hover

    &:disabled,
    &.disabled
      @include buttons.black-disabled

  &.white
    @include buttons.white

    @media (hover: hover)
      &:hover
        @include buttons.white-hover

    &:disabled,
    &.disabled
      @include buttons.white-disabled

  &.shadow
    @include buttons.shadow

    @media (hover: hover)
      &:hover
        @include buttons.shadow-hover

    &:disabled,
    &.disabled
      @include buttons.shadow-disabled

  &.transparent
    @include buttons.transparent

    @media (hover: hover)
      &:hover
        @include buttons.transparent-hover

    &:disabled,
    &.disabled
      @include buttons.transparent-disabled

  &.extra-small
    @include fonts.font-s
    padding: 5px 10px
    min-height: 28px

    &.onlyIcon
      padding: 4px
      min-width: 28px

  &.small
    @include fonts.font-s
    padding: 7px 10px
    min-height: 32px

    &.onlyIcon
      padding: 6px
      min-width: 32px

  &.medium
    @include fonts.font-s
    padding: 11px 16px
    min-height: 40px

    &.onlyIcon
      padding: 8px
      min-width: 40px

  &.large
    @include fonts.font-m
    padding: 14px 20px
    min-height: 48px

    &.onlyIcon
      padding: 10px
      min-width: 48px

  &.extra-large
    @include fonts.font-m
    padding: 18px 28px
    min-height: 56px

    &.onlyIcon
      padding: 12px
      min-width: 56px

  &.checked
    box-shadow: inset 0px 0px 0px 2px var(--color-red)

    @media (hover: hover)
      &:hover
        box-shadow: inset 0px 0px 0px 2px #FF3118B2
