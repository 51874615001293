@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.moviePoster
  display: flex
  width: 140px
  flex-direction: column
  gap: 8px
  position: relative
  // для флажка "выбор афиши"
  margin-top: 3px

.imageContainer
  position: relative

.tickets,
.okko
  position: absolute
  right: 8px
  bottom: 8px
  z-index: 1

.okko
  height: 28px

.info
  display: flex
  flex-direction: column
  gap: 4px

.buttons
  display: flex
  gap: 4px

.metaInfo
  @include fonts.font-s
  color: var(--color-grey-59)

  & > *
    color: inherit

.name
  overflow-wrap: anywhere

.agePin
  @include fonts.font-s
  padding: 4px 10px
  border-radius: 14px
  border: 1px solid var(--color-grey-88)
