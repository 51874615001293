@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.searchEmptyState
  @include fonts.font-m

.button
  display: inline
  @include buttons.default
  color: var(--color-red)
