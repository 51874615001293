@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/mixins' as mixins

$inner-padding: 24px

.layoutFooter
  flex-direction: column
  height: 420px
  margin-top: 76px
  margin-bottom: 24px

  max-width: var(--page-max-size-outer)

  width: clamp(var(--page-min-size-outer), 100vw - var(--scrollbar-width), var(--page-max-size-outer))
  box-sizing: border-box

  padding-left: var(--page-side-paddings)
  padding-right: var(--page-side-paddings)
  margin-left: auto
  margin-right: auto

  content-visibility: auto
  contain-intrinsic-size: 420px

.footer
  display: grid
  position: relative
  width: 100%
  padding: $inner-padding
  box-sizing: border-box

  &::before,
  &::after
    position: absolute
    content: ''
    background: var(--color-grey-95)
    top: 0
    bottom: 0
    z-index: -1
    pointer-events: none

  @include mixins.on-narrow
    grid-template-areas: 'qr app other' 'subscription subscription other'
    grid-template-columns: 132px 205px auto
    gap: 12px

    &::before,
    &::after
      border-radius: 24px

    &::before
      left: 0
      right: calc(100% - 395px)

    &::after
      left: 395px
      right: 0

  @include mixins.on-normal-wide
    grid-template-areas: 'qr app other' '.subscription other'
    grid-template-columns: 187px 372px auto
    gap: 48px

    &::before,
    &::after
      border-radius: 32px

    &::before
      left: 0
      right: calc(100% - 235px)

    &::after
      left: 235px
      right: 0

.smallerTop
  margin-top: 54px

.promoText
  padding: 0 $inner-padding 4px
  @include fonts.font-s
  color: var(--color-grey-45)
