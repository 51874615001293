@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.modalAction
  @include buttons.default

  padding: 12px
  margin: 4px
  border-radius: 24px
  text-align: left

  &:not(:disabled)
    cursor: none

  &:hover:not(:disabled)
    @include buttons.shadow

  &:disabled,
  &.disabled
    @include buttons.shadow-disabled
    opacity: 0.4
    color: unset
