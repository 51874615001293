@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.reviewContainer
  display: flex
  flex-direction: column
  position: relative
  border-radius: 24px
  padding: 16px
  height: calc(100% - 32px)
  background-color: var(--color-grey-95)
