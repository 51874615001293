@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.notification
  @include fonts.font-m
  display: flex
  justify-content: space-between
  align-items: center
  padding: 10px 10px 10px 16px
  gap: 12px
  cursor: pointer
  user-select: none
  box-sizing: border-box

  max-width: calc(100vw - var(--page-side-paddings) * 2)

  background: rgba(0, 0, 0, 0.7)
  backdrop-filter: blur(8px)

  border-radius: 24px

  color: white

  &:not(.visible)
    display: none
