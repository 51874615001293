@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.widgetContentEmptyState
  @include fonts.font-h3
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 40px
  padding: 24px 0
