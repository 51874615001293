@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerSubscriptionForm
  display: flex
  flex-direction: column
  gap: 16px

.text
  display: flex
  flex-direction: column
  gap: 4px

.title
  @include fonts.font-h4

.form
  display: flex
  flex-direction: column
  gap: 8px

.input
  height: 56px
  padding: 18px 28px
  border-radius: 256px
  border: 1px solid transparent

.link
  color: var(--color-grey-45)
