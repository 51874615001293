@use '~_css/ds-2023/fonts' as fonts

.metro
  display: inline-flex
  align-items: center
  vertical-align: middle

  &:not(:last-child)
    margin-right: 10px

.stations
  display: inline-flex
  margin-right: 5px

.stationColor
  position: relative
  width: 12px
  height: 8px

  &::before
    position: absolute
    top: 50%
    left: 50%
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: currentColor
    content: ''
    transform: translate(-50%, -50%)

  & + .stationColor
    margin-left: -8px

    &::before
      box-shadow: inset 0 0 1px rgba(255,255,255,0.502)

.name
  @include fonts.font-s
