.cardImage
  &.normal
    aspect-ratio: 261 / 174

  &.narrow
    aspect-ratio: 351 / 198

.image
  width: 100%

.link
  display: flex
  aspect-ratio: inherit
