.dropdownView
  border-radius: 20px
  box-shadow: 0px 4px 16px var(--color-black-15)
  background-color: white
  --spinner-color: var(--color-red)

  .content
    width: 100%

    &  > *
      padding-left: 20px
      padding-right: 20px

    & > *:first-child
      padding-top: 16px

    & > *:last-child
      padding-bottom: 20px

    &  > *:not(:first-child)
      padding-top: 10px

    &  > *:not(:last-child)
      padding-bottom: 10px

.dropdownViewHeader
  display: flex
  justify-content: space-between
  align-items: center

.dropdownViewContent
  display: block
  box-sizing: border-box

.dropdownViewContentSection
  display: block

  &.delimiter + &
    margin-top: 20px
    padding-top: 20px
    border-top: 1px solid var(--color-grey-88)

  &:not(.delimiter) + &
    margin-top: 20px
