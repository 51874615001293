@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.poster
  position: relative
  display: flex
  flex-direction: column
  gap: 8px
  flex-shrink: 0
  // для флажка "выбор афиши"
  margin-top: 3px

  &.size_small
    width: 140px

  &.size_normal
    width: 149px
