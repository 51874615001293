@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.material
  position: relative
  display: flex
  flex-direction: column
  flex-shrink: 0

  content-visibility: auto
  contain-intrinsic-height: 312px
  min-height: 292px

  &.normal
    width: 218px
    contain-intrinsic-width: 218px

  &.wide
    width: 452px
    contain-intrinsic-width: 452px
