@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.tooltip
  @include fonts.font-s
  padding: 7px 12px
  border-radius: 16px
  background: rgba(26, 26, 26, 0.80)
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10)
  color: white
