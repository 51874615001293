@use '~_css/ds-2023/fonts' as fonts

.title
  @include fonts.font-h2
  display: flex
  gap: 16px
  align-items: center
  min-height: 40px

.link
  font-family: 'Suisse'
