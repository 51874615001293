@use '~_css/ds-2023/fonts' as fonts
  
.date
  width: 72px
  position: relative
  flex-shrink: 0

.dayAndMonthBlock
  @include fonts.font-s
  white-space: nowrap

.dayOfMonth
  font-family: Afisha Display
  font-size: 36px
  line-height: 36px
  letter-spacing: -1.44px

.dayOfWeek
  &:not(.dayOff)
    color: var(--color-grey-45)

  &.dayOff
    color: var(--color-red)
