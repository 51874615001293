@use '~_css/ds-2023/mixins' as mixins

.container
  @include mixins.no-scrollbar
  overflow-y: auto
  max-height: var(--window-inner-height, 100vh)

  &.static-min-height
    height: 100vh

    & > .popup
      min-height: var(--max-content-height-in-viewport)

.popup
  display: flex
  flex-direction: column
  margin: var(--page-side-paddings) auto
  overflow: auto
  box-sizing: border-box
  max-width: var(--max-content-width-in-viewport)
  transition: background-color 0.15s ease-in-out

  &.default
    border-radius: 32px
    padding: 32px

  &.dense
    padding: 24px
    border-radius: 32px

  &.white
    background-color: white

  &.xs
    width: var(--modal-size-xs)

  &.s
    width: var(--modal-size-s)

  &.m
    width: var(--modal-size-m)

  &.l
    width: var(--modal-size-l)

  &.xl
    width: var(--modal-size-xl)
