@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerOther
  grid-area: other
  display: flex
  flex-direction: column

  @include mixins.on-narrow
    margin-left: 38px

  @include mixins.on-normal-wide
    margin-left: calc((clamp(var(--page-min-size-outer), 100vw - var(--scrollbar-width), var(--page-max-size-outer)) - (235px + 48px + 372px + 505px) - (var(--page-side-paddings) * 2)) / 2)

.groups
  display: flex
  gap: 20px
  color: var(--color-grey-45)
  // white-space: nowrap

  @media (max-width: 1000px)
    @include fonts.font-s

.group
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 15px
  // flex-basis: 160px

.link
  display: flex
  cursor: pointer

  &:hover
    color: var(--color-red)

.socials
  margin-top: auto
  display: flex
  gap: 24px

.copyright
  @include fonts.font-s
  margin-top: 24px
  color: var(--color-grey-45)
