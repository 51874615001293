@mixin font($font-size, $size-height)
  font-size: $font-size
  line-height: $size-height

@mixin font-xxl
  @include font(32px, 36px)

@mixin font-xl
  @include font(24px, 28px)

@mixin font-xl-paragraph
  @include font(24px, 31px)

@mixin font-xl-paragraph-bold
  @include font(24px, 31px)
  font-weight: bold

@mixin font-xl-numbers
  @include font(24px, 28px)
  letter-spacing: 0.06em

// ----------

@mixin font-l
  @include font(20px, 24px)

@mixin font-l-bold
  @include font(20px, 24px)
  font-weight: bold

// ----------

@mixin font-ml
  @include font(18px, 22px)

@mixin font-ml-bold
  @include font(18px, 22px)
  font-weight: bold

@mixin font-ml-paragraph
  @include font(18px, 27px)

// ----------

@mixin font-m
  @include font(15px, 20px)

@mixin font-m-paragraph
  @include font(15px, 23px)

@mixin font-m-bold
  @include font(15px, 20px)
  font-weight: bold

@mixin font-m-numbers
  @include font(15px, 20px)
  letter-spacing: 0.06em

@mixin font-m-caps
  @include font(15px, 20px)
  text-transform: uppercase

// ----------

@mixin font-s
  @include font(13px, 18px)

@mixin font-s-numbers
  @include font(13px, 18px)
  letter-spacing: 0.06em

@mixin font-s-caps
  @include font(13px, 18px)
  letter-spacing: 0.01em
  text-transform: uppercase

// ----------

@mixin font-n1
  font-family: 'Afisha Display'
  letter-spacing: -0.02em
  @include font(100px, 80px)

@mixin font-n2
  font-family: 'Afisha Display'
  letter-spacing: -0.02em
  @include font(68px, 68px)

@mixin font-n3
  font-family: 'Afisha Display'
  letter-spacing: -0.02em
  @include font(60px, 60px)

@mixin font-n3-numbers
  font-family: 'Afisha Display'
  letter-spacing: -1.2em
  @include font(60px, 60px)

@mixin font-n4
  font-family: 'Afisha Display'
  letter-spacing: -0.02em
  @include font(52px, 52px)

// ----------

@mixin font-h1
  font-family: 'Afisha Display'
  letter-spacing: -0.01em
  @include font(38px, 40px)

@mixin font-h2
  font-family: 'Afisha Display'
  letter-spacing: -0.01em
  @include font(32px, 36px)

@mixin font-h3
  font-family: 'Afisha Display'
  letter-spacing: -0.01em
  @include font(24px, 27px)

@mixin font-h4
  font-family: 'Afisha Display'
  letter-spacing: -0.01em
  @include font(22px, 26px)

@mixin font-h5
  font-family: 'Afisha Display'
  letter-spacing: -0.01em
  @include font(20px, 22px)

// ----------

@mixin font-custom-session-10-13
  @include font(10px, 13px)

@mixin font-custom-sale-13-16
  font-family: 'Afisha Display'
  @include font(13px, 16px)
  letter-spacing: -0.02em

@mixin font-custom-sale-10-13
  font-family: 'Afisha Display'
  @include font(10px, 13px)
  letter-spacing: -0.03em

@mixin font-custom-city-9-13
  @include font(9px, 13px)
  letter-spacing: 0.096em

@mixin font-custom-certificate-10-26
  font-family: 'Afisha Display'
  @include font(10px, 26px)
  letter-spacing: 0

@mixin font-custom-certificate-5-13
  font-family: 'Afisha Display'
  @include font(5px, 13px)
  letter-spacing: 0

@mixin font-custom-link-10-13
  @include font(10px, 13px)

@mixin font-custom-ad-badge-8-10
  @include font(8px, 10px)
  letter-spacing: -0.32px

@mixin font-custom-yolki-banner-28-28
  font-family: 'Afisha Display'
  @include font(28px, 28px)
  letter-spacing: -0.56px
  font-weight: 500
