@use '~_css/ds-2023/buttons' as buttons

.filePickerItem
  display: flex
  padding: 13px 15px
  border-radius: 44px
  border: 1px solid var(--color-grey-88)
  gap: 4px
  align-items: center

.remove
  @include buttons.default