@use '~_css/ds-2023/functions' as functions

.objectItem
  position: relative
  display: grid
  grid-template-columns: minmax(auto, 420px) auto
  column-gap: 60px
  align-items: flex-start

  & + &::before,
  &:last-child::after
    content: ''
    position: absolute
    left: 100px
    right: 6px
    height: 1px
    background-color: var(--color-grey-59)
    opacity: 0.2
    pointer-events: none

  & + &::before
    top: -24px

  &:last-child::after
    bottom: -24px
    height: functions.if_false(--is-last-page, 1px)
