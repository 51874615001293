.discount
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  padding: 4px 8px
  min-width: 48px
  border-radius: 6px
  background-color: var(--color-black-04)
  color: black
  font-weight: 400
  font-size: 12px
  line-height: 16px
  font-family: 'Afisha Display'
