@mixin default
  background-color: unset
  border: none
  box-sizing: border-box
  transition: background-color 0.2s, box-shadow 0.2s, color 0.2s, opacity 0.2s, outline-width 0.2s
  padding: 0

  &:not(:disabled)
    cursor: pointer

  &:focus
    outline-color: var(--color-red)

@mixin outline
  color: black
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-88)

@mixin outline-hover
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.15)

@mixin outline-disabled
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-88)

@mixin black
  background-color: black
  color: white

@mixin black-hover
  background-color: var(--color-grey-20)

@mixin black-disabled
  background-color: var(--color-grey-59)

@mixin white
  background-color: white
  color: black

@mixin white-hover
  background-color: var(--color-grey-95)

@mixin white-disabled
  background-color: white

@mixin shadow
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.15)
  color: black

@mixin shadow-hover
  background-color: var(--color-grey-95)

@mixin shadow-disabled
  background-color: white

@mixin transparent
  background: var(--color-grey-70-40)
  color: white
  backdrop-filter: blur(4px)

@mixin transparent-hover
  background-color: var(--color-grey-70-65)

@mixin transparent-disabled
  background-color: rgba(179, 179, 179, 0.2)
