@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.userRatingButton
  &.large
    width: 56px
    height: 56px

  &.medium
    @include fonts.font-m
    width: 44px
    height: 44px

  &:not(:hover):not(.selected)
    color: var(--color-grey-59)

  &.selected
    background-color: var(--color-grey-95)
