@use '~_css/ds-2023/buttons' as buttons

.pagination
  display: flex
  gap: 4px
  width: 100%
  justify-content: center

.paginationButton
  @include buttons.default
  min-width: 60px
  height: 60px
  padding: 12px 20px
  border-radius: 30px

  &:disabled
    @include buttons.outline

  &:not(:disabled)
    @include buttons.white

    &:hover
      @include buttons.white-hover

.paginationControlButton
  @include buttons.default
  @include buttons.white
  width: 30px
  height: 60px

  &:disabled
    @include buttons.white-disabled

.stub
  box-sizing: border-box
  width: 60px
  height: 60px
  padding: 12px 20px
  align-items: center
  justify-content: center
