@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.listItem
  & + &
    margin-top: calc(var(--gap) / 2 * 1px)

    &::before
      content: ''
      display: block
      height: 1px
      background-color: var(--color-grey-88)
      margin-bottom: calc(var(--gap) / 2 * 1px)
      // margin-left: calc(var(--delimiterOffsetLeft, 0) * 1px)
      // margin-right: calc(var(--delimiterOffsetRight, 0) * 1px)
