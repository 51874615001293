@use '~_css/ds-2023/fonts' as fonts

.pageTitle
  @include fonts.font-h3

.pageMeta
  @include fonts.font-s
  color: var(--color-grey-45)

.posterName
  @include fonts.font-m

.posterMeta
  @include fonts.font-s
  color: var(--color-grey-45)
