@use '~_css/ds-2023/fonts' as fonts

.scheduleItem
  display: flex
  position: relative

.details
  display: flex
  flex-direction: column
  flex-grow: 1
  gap: 6px
