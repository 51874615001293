@keyframes spinner
  0%
    box-shadow: calc(var(--_size) * -1) calc(var(--_size) * -1) 0 currentColor, var(--_size) calc(var(--_size) * -1) 0 currentColor, calc(var(--_size) * -1) var(--_size) 0 currentColor

  25%
    box-shadow: calc(var(--_size) * -1) calc(var(--_size) * -1) 0 currentColor, var(--_size) var(--_size) 0 currentColor, calc(var(--_size) * -1) var(--_size) 0 currentColor

  50%
    box-shadow: var(--_size) calc(var(--_size) * -1) 0 currentColor, var(--_size) var(--_size) 0 currentColor, calc(var(--_size) * -1) var(--_size) 0 currentColor

  75%
    box-shadow:   var(--_size) calc(var(--_size) * -1) 0 currentColor, var(--_size) var(--_size) 0 currentColor, calc(var(--_size) * -1) calc(var(--_size) * -1) 0 currentColor

  100%
    box-shadow: var(--_size) calc(var(--_size) * -1) 0 currentColor, calc(var(--_size) * -1) var(--_size) 0 currentColor, calc(var(--_size) * -1) calc(var(--_size) * -1) 0 currentColor

.spinner
  display: flex
  --_size: calc(var(--spinner-size) / 3 * 1px)
  width: var(--spinner-size)
  height: var(--spinner-size)

  &.red
    color: var(--color-red)

  &.currentColor
    color: currentColor

  &.variable
    color: var(--spinner-color)

  &::before
    margin: calc(var(--spinner-size) / 3 * 1px)
    width: var(--_size)
    height: var(--_size)
    border-radius: 50%
    content: ''
    animation: spinner 1.3s infinite !important
    pointer-events: none
