@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.layoutFooter
  margin-top: 44px
  padding-left: var(--page-side-paddings)
  padding-right: var(--page-side-paddings)
  padding-bottom: var(--page-side-paddings)

  content-visibility: auto
  contain-intrinsic-height: 938px

.section
  padding: 20px
  background-color: var(--color-grey-95)
  border-radius: 24px

  & + &
    margin-top: 12px
