.installmentPlanHowItWorksTile
  display: flex
  justify-content: center
  align-items: center
  pointer-events: none
  user-select: none

  img
    width: 100%
    height: 100%

  &:nth-child(1)
    grid-column: 1
    grid-row: 1 / span 2

  &:nth-child(2)
    grid-column: 2 / span 2
    grid-row: 1

  &:nth-child(3)
    grid-column: 2 / span 2
    grid-row: 2
