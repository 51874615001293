@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.mainBlock
  display: flex
  flex-direction: column
  width: 100vw
  place-self: center

.form
  display: flex
  flex-direction: column
  @include fonts.font-ml
  gap: 32px

.header
  display: flex
  justify-content: center
  padding: 20.5px 0

.banner
  border-radius: 26px
  width: 100%

.flexCol
  display: flex
  flex-direction: column

.infoList
  display: flex
  flex-direction: column
  gap: 16px
  list-style-type: disc
  padding-left: 21px
  & > li
    list-style-position: outside

.block
  display: flex
  flex-direction: column
  border-radius: 26px
  background-color: white
  padding: 26px
  gap: 24px

.field
  display: flex
  flex-direction: column
  gap: 16px
  & > input
    width: 100%
    padding: 14px 16px

.checkboxField
  display: flex
  flex-direction: column
  gap: 2px

.checkboxSubText
  padding-left: 34px
  color: var(--color-grey-40)
  @include fonts.font-m

.buttons
  display: flex
  flex-wrap: wrap
  gap: 4px
  & > *
    padding: 18px 16px !important
    border-radius: 28px
    min-width: 62px

.fieldHeader
  @include fonts.font-h3

.footer
  display: flex
  flex-direction: column
  align-self: center
  text-align: center
  gap: 16px
  width: 100%
  padding-top: 18px
  padding-bottom: 25px

.agreement
  color: black
  opacity: 50%
  padding-bottom: 48px

.link
  @include mixins.underline
  text-decoration-color: black

.black30
  color: black
  opacity: 30%
  padding: 0 30px

.customInput
  height: 56px