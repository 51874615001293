@use '~_css/ds-2023/fonts' as fonts

$headerCityMaxWidth: max(100px, calc(100vw - 12px - 12px - 42px - 12px - 16px - 178px))

.sberLogo
  display: flex
  align-items: center
  gap: 18px

.logo_city
  display: flex
  flex-direction: column

.city
  @include fonts.font-custom-session-10-13
  position: relative
  max-width: $headerCityMaxWidth
  overflow: hidden
  margin-top: 4px
  align-self: flex-start
  white-space: nowrap
  text-overflow: ellipsis
  text-transform: uppercase

  &::after
    content: ''
    position: absolute
    left: -6px
    right: -6px
    top: -6px
    bottom: -6px

.imageContainer
  position: relative
  display: flex
  align-items: center

  &::before
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: -10px
    content: ''
    height: 26px
    width: 1px
    background-color: var(--color-grey-85)
