@use '~_css/ds-2023/fonts' as fonts

$animation-timing: 0.4s

.visible
  opacity: 1

.hidden
  opacity: 0

.nearby
  opacity: 0.1

.nearbyLeft
  @extend .nearby
  transform: scale(0.4) translate(-225%, -65%)

.nearbyRight
  @extend .nearby
  transform: scale(0.4) translate(190%, -130%)

.transit
  transition: transform calc($animation-timing / 2) linear, opacity calc($animation-timing / 2) ease-in-out

.fade:not(.transit)
  transition: opacity $animation-timing ease-in-out

.below
  z-index: -1

.certPic
  position: absolute
  bottom: 0
  left: 0
  right: 0
  top: 0
  & > img
    position: absolute
    bottom: 0

.certificates
  display: flex
  margin-bottom: 120px

.info
  display: flex
  flex-direction: column
  gap: 24px

.header
  @include fonts.font-n3

.arrowButton
  z-index: 100

.buyButton
  margin-top: 28px
  width: 188px
  align-self: center

.certificateSurroundings
  display: flex
  align-items: center
  justify-content: space-between
  // для пикчи бинокля из-за которой страница не довольна
  overflow: hidden
  padding: 40px var(--page-side-paddings)
  margin: 0 calc(-1 * var(--page-side-paddings))

.certificateView
  transform: rotate(8deg)
  min-width: 47%
  user-select: none

.ellipse
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  transform: scale(0.95, 1)
  border-radius: 50%
  filter: drop-shadow(0 0 10px currentColor)
  z-index: -10
  transition: color $animation-timing ease-in-out

.certificateBlock
  display: flex
  flex-direction: column
  border-radius: 12px
  margin-bottom: -1px
  transition: background-color $animation-timing ease-in-out

.mainBlock
  aspect-ratio: 177 / 145
  padding: 12px
  box-sizing: border-box
  position: relative

.certificateNominal
  @include fonts.font-h3
  height: 1em
  color: white
  position: relative
  text-align: center
  padding-top: 4px
  & > *
    transition: opacity $animation-timing ease-in-out
    position: absolute
    left: 0
    right: 0

.certificateSubText
  @include fonts.font-custom-certificate-5-13
  color: black
  place-self: center
  padding-bottom: 4px


.yellow
  background-color: rgba(230, 233, 82, 1)
  color: rgba(230, 233, 82, 1)

.blue
  background-color: rgba(152, 200, 246, 1)
  color: rgba(152, 200, 246, 1)

.green
  background-color: rgba(173, 234, 145, 1)
  color: rgba(173, 234, 145, 1)

.pink
  background-color: rgba(245, 129, 255, 1)
  color: rgba(245, 129, 255, 1)

.lime
  background-color: rgba(0, 205, 104, 1)
  color: rgba(0, 205, 104, 1)

.silver
  background-color: rgba(192, 173, 0, 1)
  color: rgba(192, 173, 0, 1)

.purple
  background-color: rgba(151, 126, 255, 1)
  color: rgba(151, 126, 255, 1)

.red
  background-color: var(--color-red)
  color: var(--color-red)
