@use '~_css/ds-2023/mixins' as mixins

.link
  transition: 0.15s color, 0.15s text-decoration-color

  &.underline
    @include mixins.underline

    @media (hover: hover)
      &:hover
        color: var(--color-red)

.black-red
  color: black

  @media (hover: hover)
    &:hover
      color: var(--color-red)
      text-decoration-color: currentColor

.current-red
  @media (hover: hover)
    &:hover
      color: var(--color-red)
      text-decoration-color: currentColor

.red
  color: var(--color-red)

.grey-59
  color: var(--color-grey-59)

  @media (hover: hover)
    &:hover
      color: var(--color-red)
      text-decoration-color: currentColor
