.posterContainer
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  cursor: pointer

.poster
  & > img
    width: 100%
    height: 100%
    object-fit: contain

  &.horizontal
    width: calc(100dvw - 64px)

  &.vertical
    height: calc(100dvh - 64px)
