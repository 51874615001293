@use '~_css/ds-2023/fonts' as fonts

.title
  @include fonts.font-h1

.htmlBlock
  margin-top: 12px
  color: var(--color-grey-45)

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
