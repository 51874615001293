@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.citySwitcherPopup
  width: 100vw
  padding-bottom: 16px

.hr
  width: calc(100% - var(--page-side-paddings) * 2)
  border-top: 1px solid var(--color-grey-88)
  margin: 0 var(--page-side-paddings)

.letter
  @include fonts.font-h4

.city
  padding: 8px 0
  margin: -8px 0
  cursor: pointer
