@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimePopup
  max-height: 100vh
  overflow: auto

  border-top-left-radius: 24px
  border-top-right-radius: 24px
  background-color: white

.close
  position: absolute
  z-index: 10
  top: 8px
  right: 8px
  padding: 8px
  color: var(--color-grey-59)

.content
  position: relative
  display: flex
  flex-direction: column
  max-width: 375px - 32px
  margin: auto
  padding: 0 16px 24px

.logo
  margin-top: 32px

.title
  font-family: 'Afisha Display'
  font-size: 41px
  font-weight: 500
  line-height: 44px
  letter-spacing: -0.01em

  margin-top: 32px

  [data-green]
    color: #21A038

  &.big
    font-size: 32px
    line-height: 36px

.text1
  margin-top: 8px
  color: #8C8C8C

.text2
  margin-top: 24px
  max-width: 326px
  white-space: pre-wrap

  &.big
    font-size: 18px
    line-height: 26px

.benefits
  margin-top: 24px

  &[data-title]::before
    content: attr(data-title)
    display: block
    font-weight: bold
    margin-bottom: 6px

.benefit
  list-style-type: disc
  list-style-position: inside

  & + &
    margin-top: 12px

.benefits + .button.button
  margin-top: 24px

.button.button
  margin-top: 36px
  height: 60px
  margin-left: -8px
  margin-right: -8px
  background-color: #21A038
  color: white
  font-size: 18px
  line-height: 26px
  letter-spacing: 0.01em

.trial
  @include fonts.font-s
  margin-top: 12px

.offer
  @include fonts.font-s
  color: #8C8C8C
  margin-top: 12px
  margin-bottom: -4px
  text-align: center

.offerLink.offerLink
  text-decoration: underline
  text-decoration-color: currentColor
  text-underline-offset: 2px
  text-decoration-thickness: 1px
  color: currentColor
