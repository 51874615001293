@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.dateSession
  @include buttons.default
  text-align: unset

  display: flex
  flex-direction: column
  border-radius: 12px
  width: 128px
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-95)

.over
  position: relative
  width: 100%
  box-sizing: border-box
  padding: 5px 7px 4px 7px
  border-radius: 12px
  background-color: var(--color-grey-95)

.time_weekday
  @include fonts.font-s
  color: var(--color-grey-45)
  text-transform: uppercase
  margin-bottom: 3px

.dayOff
  color: var(--color-red)

.date_month
  display: flex
  gap: 2px
  align-items: baseline

.date
  @include fonts.font-xxl

.month
  @include fonts.font-ml
  text-transform: lowercase

.pushkin
  position: absolute
  right: 0
  bottom: 0

.under
  display: flex
  align-items: center
  justify-content: space-between
  box-sizing: border-box
  border-bottom-left-radius: 12px
  border-bottom-right-radius: 12px
  padding: 4px 4px 4px 6px
  width: 100%

.minPrice
  @include fonts.font-s
  color: var(--color-grey-45)
