@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.reviewAuthor
  display: flex
  gap: 16px
  flex-grow: 1

.avatar
  position: relative
  flex-shrink: 0
  height: 56px
  width: 56px

.afishaReviewerBadge
  position: absolute
  top: 0
  left: 36px

.name
  @include fonts.font-ml
  overflow-wrap: anywhere

.counters
  @include fonts.font-s
  margin-top: 2px
  color: var(--color-grey-45)
