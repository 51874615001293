.itemsGrid
  &.card
    display: grid
    grid-template-columns: repeat(auto-fill, var(--page-column))
    gap: 32px 16px

  &.poster
    display: grid
    gap: 36px 16px
    grid-template-columns: repeat(auto-fill, 140px)

  &.listItem
    gap: 48px
    display: flex
    flex-direction: column

  &.reviewListItem
    display: flex
    flex-direction: column
    gap: 60px

  &.scheduleItem
    display: flex
    flex-direction: column
    gap: 16px

  &.shadow
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1)
    border-radius: 32px
    padding: 32px
