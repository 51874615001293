a, button
  text-decoration: none
  color: currentColor

  &:not([class])
    color: black

  &:not([class]):hover
    text-decoration: underline

  &:focus
    outline-color: var(--color-red)

#afishaOverlay
  backdrop-filter: blur(8px)

html.is-locked,
html.is-locked body
  overflow: hidden

img[src^='https://ad.doubleclick.net'],
img[src^='//counter.rambler.ru/top100.cnt']
  display: block
  height: 0
  display: none
