@use '~_css/ds-2023/fonts' as fonts

.labeledPinControl
  display: flex
  flex-direction: column
  --pin-size: 20
  --start-end-diff: calc(var(--end) - var(--start))

.labels
  position: relative
  height: 18px
  margin: 0 calc(var(--pin-size) * 1px) 8px

  .label
    @include fonts.font-s
    position: absolute
    color: var(--color-grey-59)
    transform: translateX(-50%)
    left: calc((var(--value) - var(--start)) / var(--start-end-diff) * 100%)
    text-align: center
    white-space: nowrap

    &:first-child
      transform: translateX(-25%)

    &:last-child
      transform: translateX(-75%)
