@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.coverTopBar
  display: flex
  align-items: center
  gap: 12px
  margin-bottom: auto

  &:empty
    display: none
