.cover
  display: flex
  width: 100%

  &.fullfilled
    aspect-ratio: 1392 / 532
    --cover-name-color: white
    --cover-meta-color: var(--color-white-70)

  &:not(.fullfilled)
    min-height: 280px
    --cover-name-color: black
    --cover-meta-color: var(--color-grey-59)

.info
  border-radius: 32px
  position: relative
  margin-left: auto
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: flex-start
  padding: 24px
  gap: 16px
  background-color: rgba(0, 0, 0, 0.3)
  flex-shrink: 0

  .cover.fullfilled > &
    width: 32%

    & > .mirrorContainer
      display: flex
      align-items: center
      justify-content: flex-end
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      border-radius: 32px
      z-index: -1
      overflow: hidden

      & > .mirror
        height: 100%
        filter: blur(60px)
        transform: rotateY(180deg) scale(1.15)

  .cover:not(.fullfilled) > &
    background-color: var(--color-grey-95)
    width: 100%
    box-sizing: border-box

.image
  background-color: var(--color-grey-95)
  display: flex
  align-items: center
  justify-content: center
  margin-right: auto
  position: relative
  aspect-ratio: 947 / 532
  border-radius: 32px
  overflow: hidden
  isolation: isolate
  flex-grow: 1

  picture
    height: 100%
    width: 100%

  &::after
    content: ''
    bottom: 0
    left: 0
    width: 100%
    height: 30%
    position: absolute
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) -10.71%, rgba(0, 0, 0, 0.35) 26.42%, rgba(0, 0, 0, 0.15) 64.2%, rgba(0, 0, 0, 0.00) 99.88%)
    pointer-events: none

.buttons
  position: absolute
  bottom: 24px
  z-index: 1
  display: flex
  gap: 8px

  &.left
    left: 24px

  &.right
    right: 24px
