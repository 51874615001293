@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.calendarDay
  background: unset
  border: none
  box-sizing: border-box

  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 42px
  height: 52px
  user-select: none

  content-visibility: visible
  contain-intrinsic-width: 42px
  contain-intrinsic-height: 52px

  &.selected
    border-radius: 12px
    background: var(--color-grey-95)

    &.start
      border-top-right-radius: 0
      border-bottom-right-radius: 0

    &.end
      border-top-left-radius: 0
      border-bottom-left-radius: 0

  &.between
    background: var(--color-grey-95)

  &.start,
  &.between
    & + [data-separator]
      background: var(--color-grey-95)

  &:disabled
    pointer-events: none

    & > .dayOfWeek,
    & > .date
      opacity: 0.4

  &:not(.holiday) > .dayOfWeek
    color: var(--color-grey-59)

  &.holiday > .dayOfWeek
    color: var(--color-red)

.garland
  &::before
    content: url('/img/mobile-2023/calendar-garland/garland.svg')
    max-width: 450px
    height: 100%
    position: absolute
    transform: translateX(45%) translateY(-20px)
    z-index: 2
    pointer-events: none

.reversedGarland
  &::before
    content: url('/img/mobile-2023/calendar-garland/garland.svg')
    max-width: 450px
    height: 100%
    position: absolute
    transform: translateX(-45%) translateY(-20px)
    z-index: 2
    pointer-events: none

.dayOfWeek
  @include fonts.font-s

.date
  @include fonts.font-m-bold
