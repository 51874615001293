@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeActiveSubscriptionBanner
  @include fonts.font-s

  display: flex
  flex-direction: column

  padding: 16px 14px 12px

  border: 1px solid var(--color-grey-88)
  box-sizing: border-box
  border-radius: 20px

.info
  display: flex
  align-items: center

  border-bottom: 1px solid var(--color-grey-88)
  margin-bottom: 12px
  padding-bottom: 20px

.texts
  margin-left: 12px

.expire
  color: var(--color-grey-59)

.autoRenewal
  display: flex
  align-items: center
  justify-content: space-between
