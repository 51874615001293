@use '~_css/ds-2023/mixins' as mixins

.carouselSlider
  @include mixins.no-scrollbar()
  display: flex
  overflow-x: auto
  overflow-y: hidden
  position: relative
  scroll-behavior: smooth
  contain: paint style layout
