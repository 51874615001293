.installmentPlanTerms
  position: relative
  padding: 48px 24px
  background-color: #0a0a0a
  color: #ffffff
.installmentPlanTerm
  border-radius: 16px
  pointer-events: none
  user-select: none
  img
    display: block
    width: 100%
    height: 100%
