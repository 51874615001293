@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.userReviewFormEmotions
  display: flex
  flex-wrap: wrap
  gap: 4px
  overflow: hidden
  position: relative

  &:not(.visible)
    &:not(.extended)
      transition: max-height 0.2s 0.09s, opacity 0.1s

    &.extended
      transition: max-height 0.2s 0.1s, opacity 0.1s

  &.visible
    &:not(.extended)
      transition: max-height 0.2s, opacity 0.1s 0.175s

    &.extended
      transition: max-height 0.2s, opacity 0.1s 0.2s

  &:not(.visible)
    opacity: 0
    max-height: 0

  &.visible
    opacity: 1

    &:not(.extended)
      max-height: 30px

    &.extended
      max-height: 132px

.more
  position: absolute
  right: 0
  top: 0
  transform: rotate(90deg)
