@use '~_css/ds-2023/mixins' as mixins

.featureTile
  position: relative
  flex-shrink: 0
  margin-bottom: 6px
  isolation: isolate
  --border-radius: 20px
  border-radius: var(--border-radius)

  aspect-ratio: 1 / 1

.wide
  aspect-ratio: 3 / 2

.averageColor
  position: absolute
  top: calc(100% - 1px)
  height: 7px
  z-index: -1
  left: 12px
  right: 12px
  clip-path: inset(-40px 0 0 0 round 0 0 20px 20px)

  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0

  &::before
    background-color: var(--color-grey-85)

  &::after
    background-image: var(--avg)
