@use '~_css/ds-2023/fonts' as fonts

.coverMeta
  color: var(--cover-meta-color)

  &.small
    @include fonts.font-s

  &.medium
    @include fonts.font-m

  &.uppercase
    text-transform: uppercase
