.video
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1

  width: 100%
  height: 100%
  border-radius: var(--border-radius)
  pointer-events: none
  opacity: 0

  transition: opacity 1s

  &.visible
    opacity: 1

.image
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0
  opacity: 1
