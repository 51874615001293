@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

@keyframes slide-first
  0%
    transform: translateX(0)

  100%
    transform: translateX(calc(-100% - var(--side) - var(--side)))

@keyframes slide-loop
  0%
    transform: translateX(var(--marquee-area-width))

  100%
    transform: translateX(calc(-100% - var(--side) - var(--side)))

// @keyframes slide-last
//   0%
//     transform: translateX(var(--marquee-area-width))

//   100%
//     transform: translateX(0)

.citySwitcher
  position: relative

.cityContainer
  --width: 100px
  width: var(--width)
  display: flex
  position: relative
  overflow: hidden

  &.marquee
    --side: 8px
    margin-left: calc(var(--side) * -1)
    margin-right: calc(var(--side) * -1)
    padding-left: var(--side)
    padding-right: var(--side)

    &::before,
    &::after
      content: ''
      position: absolute
      top: 0
      bottom: 0
      width: var(--side)
      z-index: 1

    &::before
      left: 0
      background: linear-gradient(90deg, white, transparent)

    &::after
      right: 0
      background: linear-gradient(270deg, white, transparent)

    & > .city
      --delay: 2s
      --duration: 15s
      --marquee-area-width: calc(var(--side) + var(--width) + var(--side))
      position: relative
      animation: slide-first var(--duration) linear var(--delay) forwards, slide-loop var(--duration) linear calc(var(--delay) + var(--duration)) infinite
        // --loop-count: 3
        // animation: slide-first var(--duration) linear var(--delay) forwards, slide-loop var(--duration) linear calc(var(--delay) + var(--duration)) var(--loop-count), slide-last 2s linear calc(var(--duration) + var(--delay) * var(--loop-count)) forwards

      &::after
        content: attr(data-text)
        display: inline-block
        margin-left: calc(var(--marquee-area-width) / 2)

.city
  @include fonts.font-s
  text-transform: uppercase
  white-space: nowrap
  transition: color 0.15s

  &:hover
    cursor: pointer
    color: var(--color-red)

.clarificationPopupContainer
  position: absolute
  z-index: 100
  top: calc(100% + 16px)
  left: -24px
