@use '~_css/ds-2023/fonts' as fonts

.cardInfoMeta
  @include fonts.font-s
  color: var(--color-grey-59)

  &::first-letter
    text-transform: uppercase

  & > *:not(:last-child)::after
    content: ' ∙ '
    margin-left: 2px
    margin-right: 2px
