@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/functions' as functions

.ad
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
  margin-left: auto
  margin-right: auto
  width: -webkit-fill-available
  width: -moz-available

  &:not(.overflow)
    overflow: hidden

:global
  #begun_block_442450462
    // для баннера ВТБ
    min-width: 1000px

    @include mixins.on-narrow-normal
      transform: scale(0.92)

    @include mixins.on-wide
      width: functions.columns('var(--page-columns-count) - 1')

  #begun_block_496180265
    .ad_native_block
      display: block
      width: 296px
      height: 296px
      border-radius: 24px
      aspect-ratio: 1 / 1
      overflow: hidden

    .ad_native_wrapper,
    .ad_native_content-wrapper
      display: block
      width: 100%
      height: 100%

    .ad_native_content-wrapper
      position: relative
      padding: 16px
      box-sizing: border-box
      display: flex
      flex-direction: column

    ////////////////////////////////////////////////////////
    .ad_native_sponsored
      display: none
    ////////////////////////////////////////////////////////

    .ad_native_img
      width: 100%
      height: 100%
      position: absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      z-index: -1

      img
        width: 100%
        height: 100%
        object-fit: cover

      &::after
        box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.06) inset
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0

    .ad_native_title_wrapper
      display: flex
      flex-direction: column
      flex-grow: 1
      color: white

    .ad_native_title
      @include fonts.font-h5
      margin-top: auto

    .ad_native_desc
      display: none
