@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/functions' as functions

.mapObjectItem
  &.row
    display: flex
    flex-direction: column
    gap: 12px

  &.columns
    position: relative
    display: grid
    grid-template-columns: 384px auto
    column-gap: 20px
    align-items: flex-start
