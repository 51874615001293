@use '~_css/ds-2023/fonts' as fonts

.simpleArticleTile
  display: flex
  flex-direction: column
  gap: 4px

.tileContainer
  display: flex
  align-items: flex-end
  justify-content: flex-end
  gap: 16px
  flex-grow: 1

.articleName
  @include fonts.font-s
  margin-top: 2px

.articleMeta
  @include fonts.font-s
  color: var(--color-grey-45)