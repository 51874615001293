@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.profileDropdown
  border-radius: 32px

.close
  position: absolute
  right: 12px
  top: 12px

.info
  display: flex
  flex-direction: column
  padding: 20px 24px 16px

.avatar
  width: 68px
  height: 68px

.name
  margin-top: 12px
  @include fonts.font-l-bold

.email
  margin-top: 4px
  color: var(--color-grey-59)

.menu
  display: flex
  flex-direction: column
  padding-left: 8px
  padding-right: 8px

  padding-bottom: 12px
