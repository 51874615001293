@mixin on-narrow
  @media (max-width: 1279.9px)
    @content

@mixin on-normal
  @media (min-width: 1280px) and (max-width: 1591.9px)
    @content

@mixin on-wide
  @media (min-width: 1592px)
    @content

@mixin on-narrow-normal
  @include on-narrow
    @content

  @include on-normal
    @content

@mixin on-normal-wide
  @include on-normal
    @content

  @include on-wide
    @content

@mixin on-narrow-wide
  @include on-narrow
    @content

  @include on-wide
    @content

@mixin on-1440-plus
  @media (min-width: 1440px)
    @content

@mixin no-scrollbar
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none
    width: 0
    height: 0
    -webkit-appearance: none

  &::-webkit-scrollbar-button
    display: none

  &::-webkit-scrollbar-track
    display: none

  &::-webkit-scrollbar-track-piece
    display: none

  &::-webkit-scrollbar-thumb
    display: none

  &::-webkit-scrollbar-corner
    display: none

  &::-webkit-resizer
    display: none

@mixin on-desktop-screen
  @media (min-width: 701px)
    @content

@mixin on-mobile-screen
  @media (max-width: 700px)
    @content

@mixin on-desktop-platform
  html[data-d]
    @content

@mixin on-mobile-platform
  html[data-m]
    @content

@mixin underline
  text-decoration: underline
  text-decoration-color: var(--color-red)
  text-underline-offset: 4px
  text-decoration-thickness: 2px

@mixin text-line-clamp($line-clamp)
  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: $line-clamp
  overflow-wrap: anywhere
