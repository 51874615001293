@use '~_css/ds-2023/fonts' as fonts

.rating
  display: flex
  align-items: center
  justify-content: center
  box-sizing: border-box
  flex-shrink: 0
  letter-spacing: 0.78px

  &.ratingLow
    &.outline
      color: var(--color-red)
      border: 1px solid currentColor

    &.filled
      color: white
      background-color: var(--color-red)

  &.ratingNeutral
    &.outline
      color: black
      border: 1px solid var(--color-grey-88)

    &.filled
      color: white
      background-color: var(--color-grey-70-40)
      backdrop-filter: blur(6px)

  &.ratingHigh
    &.outline
      color: var(--color-green)
      border: 1px solid currentColor

    &.filled
      background-color: var(--color-green)
      color: white

  &.small
    @include fonts.font-s
    width: 42px
    height: 28px
    border-radius: 14px

  &.circle
    @include fonts.font-s
    width: 36px
    height: 36px
    border-radius: 50%

  &.large
    @include fonts.font-m
    width: 56px
    height: 36px
    border-radius: 20px

  &.circle
    @include fonts.font-s
    width: 36px
    height: 36px
    border-radius: 50%
