@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.timeSession
  display: flex
  flex-direction: column
  gap: 4px
  padding-top: 8px

.button
  @include buttons.default
  position: relative
  display: flex
  flex-direction: column
  justify-content: flex-end
  padding: 7px 8px 5px
  box-sizing: border-box
  width: 78px
  height: 52px
  border-radius: 12px
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-88)

  &:disabled
    pointer-events: none
    opacity: 0.4

.discount
  position: absolute
  right: -4px
  top: -8px

.minPrice
  color: rgba(8, 8, 8, 0.56)
  margin-bottom: auto

  &:not(.small)
    @include fonts.font-s

  &.small
    font-size: 10px
    line-height: 16px

.time
  @include fonts.font-ml

.labels
  @include fonts.font-custom-session-10-13
  color: var(--color-grey-59)
  text-align: center
