@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.featureTileFavorite
  position: absolute
  bottom: 16px
  right: 16px

  & + :last-child[data-info]
    padding-right: calc(40px + 8px)
