@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.objectCoverTopLeft
  @include fonts.font-s
  position: absolute
  top: 12px
  left: 12px
  display: flex
  align-items: center
  gap: 8px
