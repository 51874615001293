@use '~_css/ds-2023/buttons' as buttons

.quickSearchPopupView
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  flex-direction: column
  align-items: center
  content-visibility: visible
  contain: content
  z-index: 1000

  .content
    height: 100%

  &.visible
    .overlay
      opacity: 1

  &:not(.visible)
    pointer-events: none

    .overlay
      opacity: 0

.content
  position: absolute
  transition: transform 0.2s ease-in-out

.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: var(--color-black-48)
  transition: opacity 0.2s ease-in-out

  &.blured
    backdrop-filter: blur(5px)

.close
  @include buttons.default
  position: absolute
  right: 36px
  top: 36px
