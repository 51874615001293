@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.faqArticle
  :global
    span.external-link
      @include mixins.underline
      cursor: pointer

      &:hover
        color: var(--color-red)

  .links
    display: flex
    gap: 8px
    flex-wrap: wrap

  .link
    @include mixins.underline

    &:hover
      color: var(--color-red)

  summary
    list-style-type: none

  a
    text-decoration: none
    cursor: pointer

  &.desktop
    display: flex
    flex-direction: column
    gap: 25px
    max-width: 1001px
    @include fonts.font-l

    details
      background-color: var(--color-grey-95)
      border-radius: 26px
      transition: background-color 0.2s
      cursor: pointer
      user-select: none
      &:hover
        background-color: var(--color-grey-85)
      &[open]:hover
        background-color: var(--color-grey-95)

    .title
      padding: 32px 32px 36px 32px
      display: flex
      justify-content: space-between
      align-items: center
      @include fonts.font-h2

    .content
      display: flex
      flex-direction: column
      padding: 16px 32px 32px
      gap: 40px

      hr
        border-top: 2px dotted rgba(217, 217, 217, 1)
        width: 100%

    .faqBlock
      display: flex

      .blockSubtitle
        min-width: 320px
        max-width: 320px
        font-weight: 600
        padding-right: 40px

      .blockContent
        display: flex
        flex-direction: column
        gap: 24px

    ul
      display: flex
      flex-direction: column
      gap: 24px

      li
        margin: 0 80px 0 23px
        list-style-type: disc
        list-style-position: outside

    .framed
      border-radius: 16px
      background-color: var(--color-grey-88)

    .important
      display: flex
      @include fonts.font-ml
      &>*
        padding: 28px 32px
        @extend .framed

      &::before
        content: "!"
        display: flex
        align-items: center
        justify-content: center
        aspect-ratio: 1 / 1
        min-width: 127px
        @include fonts.font-n3
        @extend .framed
        @include mixins.on-narrow
          min-width: 144px

  &.mobile
    display: flex
    flex-direction: column
    max-width: 100%
    @include fonts.font-m
    gap: 12px

    details
      background-color: var(--color-grey-95)
      user-select: none
      border-radius: 20px

    .content
      display: flex
      flex-direction: column
      margin: -12px 24px 28px
      gap: 16px

      hr
        border-top: 2px dotted rgba(217, 217, 217, 1)
        width: 100%

    .faqBlock
      display: flex
      flex-direction: column

      .blockSubtitle
        width: clamp(300px, 100%, 100%)
        font-weight: 600
        padding-bottom: 8px

      .blockContent
        display: flex
        flex-direction: column
        gap: 8px

    ul
      display: flex
      flex-direction: column
      gap: 8px

      li
        margin-left: 18px
        list-style-type: disc
        list-style-position: outside

    .title
      display: flex
      justify-content: space-between
      align-items: center
      padding: 26px 24px 28px
      font-weight: 600
      @include fonts.font-ml

    .framed
      border-radius: 16px
      background-color: var(--color-grey-88)

    .important
      display: flex
      @include fonts.font-s

      &>*
        padding: 12px 16px
        @extend .framed

      &::before
        content: "!"
        display: flex
        align-items: center
        justify-content: center
        min-width: 64px
        max-width: 64px
        @include fonts.font-h1
        @extend .framed

.faqHeader
  &.desktop
    display: flex
    flex-direction: column
    gap: 16px
    margin-bottom: 44px
    max-width: 1001px
    @include fonts.font-l
    h1
      @include fonts.font-h1

  &.mobile
    display: flex
    flex-direction: column
    gap: 16px
    max-width: 100%
      @include fonts.font-m
    h1
      @include fonts.font-h2
