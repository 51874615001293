.checkbox
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  box-sizing: border-box

  content-visibility: auto
  contain-intrinsic-height: 24px

  &.reversed
    flex-direction: row-reverse

  --checkbox-color: var(--color-red)

.input
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  z-index: 1

  &:checked
    & ~ .marker
      background-color: var(--checkbox-color)
      border-color: var(--checkbox-color)

  &:not(:checked)
    & ~ .marker
      .icon
        opacity: 0

  &:disabled
    cursor: not-allowed

    & ~ .marker
      opacity: 0.5

    & ~ .text
      opacity: 0.40

  &:not(:disabled)
    cursor: pointer

.text
  transition: 0.2s

.text[data-badge]::after
  color: currentColor
  content: ' (' attr(data-badge) ')'

.marker
  display: flex
  flex-shrink: 0
  justify-content: center
  align-items: center
  box-sizing: border-box
  border: 1px solid #D9D9D9
  border-radius: 6px
  transition: 0.2s
  width: 24px
  height: 24px
