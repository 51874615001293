@keyframes shine
  to
    background-position: right -40px top 0

.skeleton
  border-radius: 16px
  background-color: var(--color-grey-95)
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0))
  background-position: left -40px top 0
  background-size: 40px 100%
  background-repeat: no-repeat
  animation: shine 1s ease infinite
