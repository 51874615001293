@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerSection
  display: flex
  flex-direction: column
  gap: 40px
  padding: 20px
  border-radius: 24px
  background-color: var(--color-grey-95)

  & + &
    margin-top: 12px
