.commonLogo
  align-items: center
  gap: 16px
  display: flex
  flex-shrink: 0

.logoTextAndCity
  display: flex
  flex-direction: column
  gap: 3px

  .logoText
    height: 20px
    color: currentColor
