@keyframes fadein
  0%
    opacity: 0

  100%
    opacity: 1

.video
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%
  border-radius: var(--border-radius)
  pointer-events: none
  transition: opacity 0.2s ease-in-out 0.2s
  opacity: 0

  &.visible
    opacity: 1

.image
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 0
  border-radius: 32px
