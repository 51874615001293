@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

@keyframes fade-in
  0%
    opacity: 0

  100%
    opacity: 1

.parallaxLayout
  position: relative
  display: flex
  flex-direction: column

.parallax
  position: sticky
  top: 6px
  width: 100vw

  aspect-ratio: 375 / 235
  contain-intrinsic-width: 100vw
  contain-intrinsic-height: calc(100vw / (375 / 235))
  contain: size layout style

  &:not(.visible)
    content-visibility: hidden
    visibility: hidden
    opacity: 0

  &.visible
    transition: opacity 0.2s
    opacity: 1

.content
  display: flex
  flex-direction: column
  z-index: 2
  background-color: white

.parallax + .content
  border-top-left-radius: var(--parallax-offset)
  border-top-right-radius: var(--parallax-offset)
  margin-top: calc(var(--parallax-offset) * -1)
  padding-top: 16px
