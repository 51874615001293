@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.container
  margin: 12px var(--page-side-paddings) 32px

.draggableLabelContainer
  position: relative
  height: 12px
  margin: 0 32px

.draggableLabel
  position: absolute
  transform: translateX(-50%)
  padding: 5px 10px 4px 12px
  background-color: var(--color-grey-40-92)
  border-radius: 6px
  color: white
  white-space: nowrap
  z-index: 1
  top: -8px

  left: 0
  display: none
