@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.layoutHeader
  display: flex
  flex-direction: column
  gap: 16px
  padding-top: var(--page-side-paddings)
  padding-bottom: 16px
  content-visibility: auto
  contain-intrinsic-height: 126px
