.carouselShadows
  position: relative
  overflow: hidden

  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    height: 100%
    width: 98px
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)
    pointer-events: none

  &::before
    left: 0
    transform: scale(-1, 1)
    z-index: 1

  &::after
    right: 0

  &.left
    &::before
      display: none

  &.right
    &::after
      display: none

  &:not(.left)
    &::before
      display: block

  &:not(.right)
    &::after
      display: block
