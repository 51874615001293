@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.reviewVote
  display: flex
  align-items: center
  gap: 20px

.counter
  display: flex
  align-items: center
  gap: 10px

.count
  color: var(--color-grey-59)
  min-width: 1ch
