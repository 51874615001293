.control
  position: absolute
  top: 0
  bottom: 0
  z-index: 1
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  opacity: 0
  transition: opacity 0.2s
  height: -webkit-fit-content
  margin: auto 0px

  &.right
    right: 16px

  &.left
    left: 16px
    transform: rotate(180deg)

  &:hover
    opacity: 1

  &:not(.visible)
    opacity: 0
    pointer-events: none
