:root
  --color-grey-20: #333333
  --color-grey-40: #666666
  --color-grey-45: #737373
  --color-grey-59: #969696
  --color-grey-70: #B3B3B3
  --color-grey-85: #D9D9D9
  --color-grey-88: #E1E1E1
  --color-grey-92: #EBEBEB
  --color-grey-95: #F2F2F2
  --color-grey-97: #F5F5F5
  --color-red: #FF3118
  --color-green: #31BE00
  --color-lime: #A4FF30
  --color-cyan: #B1DAFF
  --color-yellow: #FFF72D

  --color-black-04: rgba(0, 0, 0, 0.04)
  --color-black-06: rgba(0, 0, 0, 0.06)
  --color-black-12: rgba(0, 0, 0, 0.12)
  --color-black-15: rgba(0, 0, 0, 0.15)
  --color-black-48: rgba(0, 0, 0, 0.48)

  --color-white-06: rgba(255, 255, 255, 0.06)
  --color-white-10: rgba(255, 255, 255, 0.1)
  --color-white-50: rgba(255, 255, 255, 0.5)
  --color-white-70: rgba(255, 255, 255, 0.7)
  --color-white-90: rgba(255, 255, 255, 0.9)

  --color-grey-10-65: rgba(26, 26, 26, 0.65)
  --color-grey-40-92: rgba(102, 102, 102, 0.92)
  --color-grey-45-34: rgba(115, 115, 115, 0.34)
  --color-grey-70-20: rgba(178, 178, 178, 0.20)
  --color-grey-70-40: rgba(178, 178, 178, 0.40)
  --color-grey-70-65: rgba(178, 178, 178, 0.65)
  --color-grey-70-90: rgba(178, 178, 178, 0.9)
  --color-grey-85-34: rgba(217, 217, 217, 0.34)
  --color-grey-88-30: rgba(255, 255, 255, 0.3)

  --modal-size-xs: 480px
  --modal-size-s: 620px
  --modal-size-m: 800px
  --modal-size-l: 920px
  --modal-size-xl: 1040px
