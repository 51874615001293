.adFallbackBlock
  width: 575px
  margin: 0px auto
  display: flex
  flex-direction: column
  gap: 16px

.skeletonControls
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 4px
