@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
  
.newYearGiftBanner
  margin-top: 44px
  margin-bottom: 44px
  padding: 20px 16px
  background-color: var(--color-grey-95)
  border-radius: 16px
  margin-left: 12px
  margin-right: 12px
  overflow: hidden

.row
  display: flex
  flex-direction: row

.column
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: self-start
  gap: 14px

.texts
  display: flex
  flex-direction: column
  gap: 6px

.text1
  white-space: nowrap
  @include fonts.font-h4

.text2
  color: var(--color-grey-59)
  @include fonts.font-m
  width: 201px

.ovals
  position: absolute
  right: 13px
  width: 95px
  height: 72px
  transform: translateY(-10px)

.candy
  width: 113px
  position: relative
  top: 65px
  right: 40px
