@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.cmsHtml
  @include fonts.font-m-paragraph
  max-width: 100vw
  overflow: hidden

  // я не ебу что здесь происходит
  :global
    ul
      margin: 16px 0
      padding-left: 48px
      list-style-type: disc

      li::marker
        color: var(--color-red)

    div,
    p,
    .b-copyright
      img
        width: 100%
        border-radius: 16px

    h2
      @include fonts.font-h2
      margin-bottom: 24px

    h3
      @include fonts.font-h3
      font-weight: lighter
      margin-bottom: 20px

      a
        @include fonts.font-l-bold

    p
      &:empty
        display: none

      &.b-copyright
        @include fonts.font-s
        color: var(--color-grey-59)

        img
          margin-bottom: 10px

      & + h2
        margin-top: 36px

      &:has(iframe)
        margin: 36px 0px !important

      &:not(.small-comment) + p
        margin-top: 8px

      &.b-copyright + p.b-copyright
        margin-top: 0px

      &.small-comment
        @include fonts.font-m-paragraph
        color: var(--color-grey-59)

        & + h3
          margin-top: 6px

          & > a
            @include fonts.font-l
            font-weight: 400
            cursor: pointer
            color: black !important
            transition: color 0.1s ease-in-out

            &:hover
              color: var(--color-red) !important
              text-decoration: none

            & > strong
              font-weight: 400
    a,
    span.external-link
      cursor: pointer
      @include mixins.underline

      &:hover
        color: var(--color-red) !important

    hr
      border-top: 1px solid var(--color-grey-59)
      margin: 24px 0px

    blockquote
      position: relative
      margin-left: 0
      margin-right: 0

      &:not(:first-child)
        margin-top: 36px

      &:not(:last-child)
        margin-bottom: 36px

      p
        @include fonts.font-l-bold

      &:before
        content: url('/img/svg/misc/i-icon-quotes.svg')
        display: block
        width: 40px
        height: 40px

    iframe
      &[src^='https://www.youtube.com/embed'],
      &[src^='https://vk.com/']
        aspect-ratio: 16 / 9
        height: 100%
        border-radius: 16px
        overflow: hidden
