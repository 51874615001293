.objectItemInfoImage
  display: flex
  position: absolute
  left: 0
  top: 0

  &.square
    width: 80px
    height: 80px

    & > .image
      border-radius: 12px

  &.circle
    width: 80px
    height: 80px

    & > .image
      border-radius: 50%

  &.poster
    width: 80px
    height: 120px

    & > .image
      border-radius: 10px
