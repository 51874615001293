@use '~_css/ds-2023/fonts' as fonts

.thingCard
  width: var(--page-column)

.thingImage
  aspect-ratio: 296/198

  & img
    width: 100%
    background-color: var(--color-grey-95)
    object-fit: contain

.image
  width: 100%
  height: 100%

.thingName
  @include fonts.font-ml

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
