.adFallbackContainer
  background-color: var(--color-grey-97)
  display: flex
  flex-direction: column
  padding: 8px 0px 22px
  gap: 8px

.scrollLayerContainer
  display: flex
  flex-direction: row
  gap: 8px
  width: 100vw
  scrollbar-width: none

.insteadOfAd
  color: var(--color-grey-59)
  text-align: center
  font-size: 13px
