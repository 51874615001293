@use '~_css/ds-2023/mixins' as mixins
  
.adArticleCard
  display: flex
  flex-direction: row
  position: relative
  min-width: 575px
  scroll-snap-align: center
  
.articleInfo
  border-radius: 16px
  background-color: #FFFFFF
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 16px

.articleInfoName
  @include mixins.text-line-clamp(3)

.articleNameBeforeColon
  font-weight: bold

.articleInfoUnderlay
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-end

.articleInfoDate
  font-size: 13px
  color: var(--color-grey-59)
