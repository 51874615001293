@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.screenSizeElement
  --_base-width: max(var(--min-page-size), 100vw)

  --width: calc(var(--_base-width) - var(--offset) - (var(--page-side-paddings) * 2))

  width: var(--width)
  box-sizing: border-box
