@use '~_css/ds-2023/fonts' as fonts

.LegalInfo
  display: flex
  flex-direction: column
  padding: 8px var(--page-side-paddings) 24px
  gap: 32px
  width: calc(100% - 2 * var(--page-side-paddings))

  .list
    display: flex
    flex-direction: column
    gap: 12px

  .header
    @include fonts.font-h2