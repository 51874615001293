@use '~_css/ds-2023/fonts' as fonts

.error
  min-height: calc(100vh - 80px)
  display: flex
  align-items: center
  justify-content: center

.container
  height: 100%
  max-width: 947px

.text
  @include fonts.font-n3

.suggest
  @include fonts.font-l
