@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.allReviewsTitle
  display: flex
  align-items: flex-start
  gap: 20px

.title
  display: flex
  flex-direction: column
  gap: 12px
  max-width: 600px

.name
  @include fonts.font-h2

.meta
  @include fonts.font-s
  color: var(--color-grey-45)
