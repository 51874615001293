@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.control
  position: absolute
  top: 0
  bottom: calc(100% - 130px)
  z-index: 1

  display: flex
  justify-content: center
  align-items: center

  transition-property: opacity
  transition-duration: 0.2s
  overflow: hidden

  width: 65px

  cursor: pointer

  &:not(.visible)
    opacity: 0
    pointer-events: none

  & > div
    z-index: 1

  &::after
    content: ''
    position: absolute
    top: 0
    height: 100%
    width: 100%
    pointer-events: none

.left
  left: 0
  justify-content: flex-start

  & > div
    transform: rotate(180deg)

  &::after
    background: linear-gradient(to right, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)

.right
  right: 0
  justify-content: flex-end

  &::after
    background: linear-gradient(to left, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)

.control:not(:hover) > div
  opacity: 0
