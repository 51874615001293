.tileContent
  position: absolute
  top: 0px
  left: 0px
  right: 0px
  bottom: 0px
  z-index: 2

  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-end
  gap: 16px

  color: white
  padding: 24px
  border-radius: var(--border-radius)
  pointer-events: none

  & button,
  & a
    pointer-events: auto
