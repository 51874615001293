@use '~_css/ds-2023/fonts' as fonts

.ad
  display: flex
  align-items: center
  gap: 1px

.adBorder
  position: relative
  &.dark
    opacity: 0.2

  & > *
    position: absolute
    border-left: 1px solid var(--color-grey-88)

.icon
  position: relative
  & > *
    position: absolute
    left: 0

.okkoButton.okkoButton
  &.extra-large
    padding: 18px 20px
  &.large
    padding: 15px 20px
    @include fonts.font-s
  &.medium
    padding: 11px 16px
  &.small
    padding: 7px 10px
  &.extra-small
    padding: 5px 8px 5px 6px
    height: 28px