@use '~_css/ds-2023/fonts' as fonts

.fancyButton
  padding: 26px 32px 28px
  background-color: var(--color-grey-95)
  border-radius: 26px
  transition: 0.2s
  color: inherit
  border: none
  outline: inherit
  cursor: pointer
  text-align: start
  width: 100%

  &.s
    @include fonts.font-m
    padding: 26px 32px 28px

  &.m
    @include fonts.font-ml
    padding: 26px 24px

  &.l
    @include fonts.font-h2
    padding: 32px 32px 36px 32px

  &:hover
    padding-left: 44px
    background-color: var(--color-grey-85)
