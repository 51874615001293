@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.section
  display: flex
  flex-direction: column

  &:not(.noMarginToBottom) + &:not(.noMarginFromTop)
    margin-top: 44px

  &:not(.noMarginToBottom) + [data-marker] + &:not(.noMarginFromTop),
  &:not(.noMarginToBottom) + [data-marker] + [data-marker] + &:not(.noMarginFromTop),
  &:not(.noMarginToBottom) + [data-marker] + [data-marker] + [data-marker] + &:not(.noMarginFromTop),
  &:not(.noMarginToBottom) + [data-marker] + [data-marker] + [data-marker] + [data-marker] + &:not(.noMarginFromTop)
    margin-top: 44px

  &.sidePaddings
    padding-left: var(--page-side-paddings)
    padding-right: var(--page-side-paddings)

  &:not(.sidePaddings)
    & > .header
      padding-left: var(--page-side-paddings)
      padding-right: var(--page-side-paddings)

.header
  display: flex
  align-items: center
  justify-content: space-between
  gap: 8px

.title
  @include fonts.font-h4
  padding: 1px 0

.more.more
  height: 28px
  white-space: nowrap
  padding-right: 4px
