@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.quickSearch
  --offset-top: calc(96px)
  display: flex
  flex-direction: column
  margin-top: var(--offset-top)
  max-height: calc(var(--max-content-height-in-viewport) - var(--offset-top))
  width: var(--modal-size-s)
  gap: 16px

.quickSearchInput
  width: 100%
  background: 16px/24px no-repeat url(/img/svg/__imported__/i-icon--search-medium.svg), white
  padding-left: 52px
  flex-shrink: 0
  height: 56px

  &::-webkit-search-cancel-button
    background: center/24px no-repeat url(/img/svg/__imported__/i-icon--x-big.svg)
    height: 24px
    width: 24px
    cursor: pointer
    filter: contrast(0)

.close
  position: fixed
  cursor: pointer
  right: 36px
  top: 36px
  color: white
