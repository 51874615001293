@use '~_css/ds-2023/fonts' as fonts

.installmentPlanSection
  display: flex
  flex-direction: column
  align-items: center

.title
  @include fonts.font-h1
  position: relative
  z-index: 2
  margin-bottom: 40px
  text-align: center
