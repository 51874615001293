@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerAppSection
  display: flex
  flex-direction: column
  align-items: self-start
  gap: 24px

.text
  @include fonts.font-h4

.link
  display: block

  img
    display: block
    object-fit: cover
