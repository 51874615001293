@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerOtherSection
  display: flex
  flex-direction: column
  gap: 24px

.socials
  display: flex
  gap: 24px

.copyright
  @include fonts.font-s
  color: var(--color-grey-59)
