@use '~_css/ds-2023/fonts' as fonts

.headerMenu
  display: flex
  flex-direction: column
  gap: 4px
  padding: 12px
  box-sizing: border-box
  height: calc(12px + 48px + 12px)
  z-index: 1
  position: relative

  &.opened
    background: white
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
    border-radius: 32px
    height: auto
    position: relative

    &::before
      content: ''
      position: absolute
      top: -28px
      left: -28px
      right: -28px
      bottom: -28px
      z-index: -1

  .menuItem.menuItem,
  .selectedItem.selectedItem
    @include fonts.font-s
    padding: 15px 16px

.top
  display: flex
  gap: inherit

.visibleMenu
  display: flex
  overflow: hidden
  gap: 4px
  flex-wrap: wrap
  height: 48px

  padding: 2px
  margin: -2px

  & > .menuItem
    display: flex
    flex-grow: 1

.offscreenMenu
  display: flex
  flex-wrap: wrap
  gap: inherit

.daily,
.selectedItem
  height: 48px
  flex-shrink: 0

.daily
  width: 98px

  & svg
    color: #8E00FF

.more
  height: 48px
  width: 48px

  &:not(.visible)
    opacity: 0
    pointer-events: none
    user-select: none
