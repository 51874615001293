@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.cityClarificationPopup
  position: relative
  display: flex
  flex-direction: column
  background-color: white
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2)
  border-radius: 24px
  box-sizing: border-box
  padding: 24px
  gap: 20px
  width: 360px

.marker
  position: absolute
  top: -12px
  left: 28px

.clarification
  @include fonts.font-h4

.buttons
  display: flex
  gap: 8px

.topRow
  display: flex
  align-items: flex-start
  justify-content: space-between

.close
  all: initial
  cursor: pointer
  z-index: 100
  margin: -4px
