@use '~_css/ds-2023/fonts' as fonts

.section
  display: flex
  flex-direction: column
  gap: 24px

  & + &
    margin-top: 60px

.header
  display: flex
  gap: 16px
  align-items: center
  min-height: 40px

.title
  @include fonts.font-h2

.more.more.more
  height: 40px
  align-self: flex-end
  padding: 11px 12px 11px 16px

.content
  display: flex
  flex-direction: column
