.userAvatar
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  cursor: pointer
  flex-shrink: 0

.image
  width: 100%
  height: 100%
  border-radius: 50%
  flex-shrink: 0
  background-color: var(--color-grey-95)
