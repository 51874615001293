.ratingTab
  white-space: pre

  &[data-count]::after
    content: ' ' attr(data-count)
    color: var(--color-grey-59)

.separator
  margin: auto 5px
  pointer-events: none

  &:first-child
    display: none

  &::before
    content: ''
    display: block
    background-color: var(--color-grey-88)
    width: 1px
    height: 24px
