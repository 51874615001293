@use '~_css/ds-2023/fonts' as fonts

.coverName
  @include fonts.font-h1
  color: var(--cover-name-color)

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
  overflow-wrap: anywhere

  @media (min-width: 1100px)
    -webkit-line-clamp: 4

  @media (min-width: 1200px)
    -webkit-line-clamp: 5
