.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1000
  width: 100%
  height: 100%

  &.isDelayed
    transition: opacity 0.2s 0.2s

  &:not(.isVisible)
    opacity: 0
    pointer-events: none
    content-visibility: hidden

  &.white
    background: var(--color-white-06)

  &.black
    background: var(--color-black-06)

  &.isCentered
    display: flex
    justify-content: center
    align-items: center
