@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sidebarSection
  display: flex
  flex-direction: column
  flex-grow: 1

.container
  position: sticky
  top: var(--page-side-paddings)
