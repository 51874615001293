.installmentPlanHowItWorks
	display: flex
	flex-direction: column
.installmentPlanHowItWorksTile
	display: flex
	justify-content: center
	align-items: center
	pointer-events: none
	user-select: none
	img
		width: 100%
		height: 100%
