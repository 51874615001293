@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.placeItemMovies
  display: flex
  gap: 8px

  &.start
    justify-content: flex-start

  &.end
    justify-content: flex-end

.lastPoster
  position: relative

  &[data-rest-count]::before
    @include fonts.font-xl
    content: "+" attr(data-rest-count)
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    display: flex
    align-items: center
    justify-content: center
    color: white
    background: #00000066
    border-radius: 8px
    pointer-events: none

.tooltip
  @include fonts.font-s
  padding: 7px 12px
  border-radius: 16px
  background: rgba(26, 26, 26, 0.80)
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10)
  color: white
