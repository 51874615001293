.installmentPlanSection
  display: flex
  flex-direction: column
  box-sizing: border-box
  padding: 16px
.title
  margin-bottom: 16px
  text-align: left
  letter-spacing: -0.004em
  font-weight: 500
  font-size: 28px
  font-family: 'Afisha Display'
  line-height: 32px
