@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.objectCover
  position: relative
  display: flex
  flex-direction: column
  contain: layout style
  height: 100%
  width: 100%
  color: white

.picture
  position: relative
  width: 100%
  height: 100%
  border-top-left-radius: 24px
  border-top-right-radius: 24px
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.06) inset

  &::after
    content: ''
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0
    overflow: hidden
    border-top-left-radius: 24px
    border-top-right-radius: 24px
    box-shadow: 0px 0px 100px 0px rgb(0 0 0 / 6%) inset

.media
  object-fit: cover
  border-top-left-radius: 24px
  border-top-right-radius: 24px
  width: 100%
  height: 100%
  aspect-ratio: 375 / 235

.cycledVideo
  position: absolute
  border-top-left-radius: 24px
  border-top-right-radius: 24px
  width: 100%
  height: 100%
  left: 0
  right: 0
  top: 0
  bottom: 0
  pointer-events: none
