.months
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px

.title
  position: relative
  margin-bottom: 8px
  user-select: none
  font-weight: bold
  height: 32px

  &::before
    content: ''
    position: absolute
    left: 0
    right: 0
    bottom: 0
    height: 1px
    background-color: var(--color-grey-92)

.days
  display: grid
  grid-template-columns: repeat(7, 42px)
  gap: 4px 8px

.day
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 42px
  height: 44px
  user-select: none

  &.selected
    border-radius: 12px
    background-color: var(--color-grey-95)

    &.start
      border-top-right-radius: 0
      border-bottom-right-radius: 0

      &:not(:nth-child(7n))::after
        content: ''
        position: absolute
        left: 100%
        bottom: 0
        top: 0
        width: 8px
        background-color: var(--color-grey-95)

    &.end
      border-top-left-radius: 0
      border-bottom-left-radius: 0

      &:not(:nth-child(7n + 1)):not(:last-child)::before
        content: ''
        position: absolute
        right: 100%
        bottom: 0
        top: 0
        width: 8px
        background-color: var(--color-grey-95)

  &.between
    background-color: var(--color-grey-95)

    &:not(:nth-child(7n))::after
      content: ''
      position: absolute
      left: 100%
      bottom: 0
      top: 0
      width: 8px
      background-color: var(--color-grey-95)

  &.holiday
    color: var(--color-red)

  &.disabled
    pointer-events: none
    color: var(--color-grey-59)
