@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.widgetContentPages
  display: flex
  flex-direction: column
  position: relative

  .page
    gap: inherit

    &:not(:last-of-type)
      --is-last-page: 0

    &:last-of-type
      --is-last-page: 1

  &.card
    gap: 32px 16px

    .page
      display: grid
      grid-template-columns: repeat(auto-fill, var(--page-column))

  &.tile
    gap: 16px

    .page
      display: grid
      grid-template-columns: repeat(auto-fill, var(--page-column))
      grid-auto-flow: dense

  &.poster
    gap: 36px 16px

    .page
      display: grid
      grid-template-columns: repeat(auto-fill, 140px)

  &.listItem
    gap: 48px

    .page
      display: flex
      flex-direction: column

  &.reviewListItem
    gap: 60px

    .page
      display: flex
      flex-direction: column

  &.scheduleItem
    gap: 16px

    .page
      display: flex
      flex-direction: column

  &.three-column-grid
    gap: 12px 12px

    .page
      display: grid
      grid-template-columns: repeat(3, minmax(0, 1fr))

  &.shadow
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1)
    border-radius: 32px
    padding: 32px

.loader
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 10
  top: -16px
  bottom: -16px
  left: -16px
  right: -16px
  transition: backdropfilter 0.2s, opacity 0.2s

  &:not(.visible)
    pointer-events: none
    opacity: 0

  &.visible
    pointer-events: initial
    opacity: 1
    backdrop-filter: blur(5px)
