@use '~_css/ds-2023/fonts' as fonts

.creationSessionsLabelsGrid
  display: flex
  flex-direction: column
  gap: 20px

.labeledSessions
  display: flex
  flex-direction: column
  gap: 8px

.label
  @include fonts.font-s
  font-weight: 500
