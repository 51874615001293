.picture
  display: flex
  overflow: hidden
  isolation: isolate

  & > img
    transition: filter 0.3s, transform 0.5s ease
    border-radius: inherit

  & > img
    &[data-loaded]
      background-image: none !important

    &:not([data-loaded])
      filter: blur(10px)
      background-position: center center
      background-size: cover
      background-repeat: no-repeat

  &.flexible
    & > img
      width: 100%
      height: 100%
      object-fit: cover

  &.cover
    & > img
      object-fit: cover

  &.gradient,
  &.box-shadow
    &::after
      content: ''
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0
      pointer-events: none
      border-radius: var(--border-radius)

  &.gradient
    &::after
      background: linear-gradient(to bottom, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 15%) 25%, rgb(0 0 0 / 40%) 100%)

  &.box-shadow
    &::after
      box-shadow: 0px 0px 100px 0px rgb(0 0 0 / 6%) inset

  @media (hover: hover)
    &.hover_2
      &:hover > img
        transform: scale(1.02)

    &.hover_3
      &:hover > img
        transform: scale(1.03)

.picture,
.fallback
  position: relative
  border-radius: var(--border-radius)

  &::after
    border-radius: inherit

  &:not(.shrink)
    flex-shrink: 0

.fallback
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  overflow: hidden

  @media (hover: hover)
    &:hover::before
      transform: scale(1.1)

  &::before
    content: ''
    transition: transform 0.5s ease
    background-image: var(--fallback-src)
    display: block
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    border-radius: inherit

.avatar
  width: 100%
  display: flex
  justify-content: center
  align-items: center

  &[data-first-letter]::after
    content: attr(data-first-letter)
    font-family: "Afisha Display"
    line-height: 31px
    letter-spacing: -0.01em
