@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberSpasiboPopup
  display: flex
  flex-direction: column
  gap: 24px

  width: 360px
  padding: 0 20px 24px
  box-sizing: border-box

  border-radius: 24px

  background: no-repeat center -20px/360px 130px url("/img/common/sber-spasibo/sber-spasibo-c.webp"), no-repeat 0 0/100% 284px url("/img/common/sber-spasibo/sber-spasibo-bg.png"), white

.close
  position: absolute
  z-index: 10
  top: 8px
  right: 8px
  padding: 8px
  color: white
  cursor: pointer

.header
  position: relative
  text-align: center
  color: white

  .title
    font-size: 24px
    line-height: 28px

  .subTitle
    margin-top: 4px
    font-size: 13px
    line-height: 18px

.spasiboLogo
  width: 116px
  height: 40px
  margin: 70px auto 16px

.info
  position: relative
  display: flex
  flex-direction: column
  gap: 8px
  padding: 16px
  box-shadow: 0px 4.45px 17.8px 0px #0000000F, 0px 1.11px 4.45px 0px #0000000A
  background-color: white
  border-radius: 16px

.benefit
  position: relative
  display: flex
  flex-direction: column
  gap: 4px
  padding-top: 12px
  padding-bottom: 12px
  padding-left: 36px

  .icon
    border-radius: 50%
    position: absolute
    left: 0
    top: 8px

  .title
    font-weight: bold
    font-size: 14px
    line-height: 18px

  .subTitle
    font-size: 13px
    line-height: 18px
    color: var(--color-grey-45)

    img
      display: inline
      vertical-align: top

.details.details
  display: flex
  align-items: center
  gap: 4px
  padding: 0 8px
  color: #21A038
  @include fonts.font-s
  letter-spacing: -0.26px

  &:hover
    color: #21A038

.button.button
  background-color: #21A038
  color: white
  height: 60px

  &:hover
    color: white
