.sberLogo
  display: flex
  align-items: center
  gap: 10px

.logoTextContainer
  display: flex
  align-items: center
  flex-direction: column
  gap: 3px

.logoText
  height: 20px

.imageContainer
  display: flex
  align-items: center
  height: 31px
  border-left: 1px solid var(--color-grey-85)
  padding-left: 10px
