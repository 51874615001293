@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeActiveSubscriptionBanner
  @include fonts.font-m

  display: flex
  align-items: center
  height: 92px

  padding: 0 18px 0 26px

  border: 1px solid var(--color-grey-88)
  box-sizing: border-box
  border-radius: 20px

.texts
  margin-left: 38px

.expire
  color: var(--color-grey-59)

.autoRenewal
  margin-left: auto
  display: flex
  align-items: center
  gap: 16px
