.footer
  position: sticky
  bottom: 0
  display: flex
  flex-direction: column
  margin-top: -24px
  padding: 24px var(--page-side-paddings) 16px
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 73%, transparent 100%)

.rating
  display: flex
  flex-direction: column

.content
  padding: 0 12px
  box-sizing: border-box
  &>*
    width: 100%

.error
  color: var(--color-red)
  padding-top: 12px

.paddedError
  @extend .error
  padding-left: 12px