@use '~_css/ds-2023/fonts' as fonts

.pinControl
  position: relative
  display: flex
  align-items: center
  height: calc(var(--pin-size) * 1px)
  --pin-size: 20
  --start-end-diff: calc(var(--end) - var(--start))
  --start-min-diff: calc(var(--min) - var(--start))
  --end-max-diff: calc(var(--end) - var(--max))

  &.labels
    margin-top: 26px

.line
  position: absolute
  right: calc(var(--pin-size) * 0.5px)
  left: calc(var(--pin-size) * 0.5px)
  display: flex
  align-items: center
  height: 4px
  background-color: var(--color-grey-88)

  .highlighted
    position: absolute
    right: calc(var(--end-max-diff) / var(--start-end-diff) * 100%)
    left: calc(var(--start-min-diff) / var(--start-end-diff) * 100%)
    height: 4px
    border-radius: 4px
    background-color: var(--color-red)
    content: ''

.draggingLabelWrapper
  @include fonts.font-s
  position: absolute
  top: 0
  right: calc(var(--pin-size) * 1px)
  bottom: 0
  left: calc(var(--pin-size) * 1px)
  display: none

  &::before
    position: absolute
    bottom: calc(100% + 2px)
    left: calc((var(--value) - var(--start)) / var(--start-end-diff) * 100%)
    padding: 5px 12px
    border-radius: 6px
    background-color: var(--color-grey-40-92)
    color: white
    content: attr(data-text)
    white-space: nowrap
    transform: translateX(-50%)

.inputs
  position: relative
  margin: 0 calc(var(--pin-size) * 0.5px)
  width: 100%
  height: 100%

.input
  position: absolute
  width: 100%
  height: calc(var(--pin-size) * 1px)
  background-color: transparent
  pointer-events: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

  &::-webkit-slider-runnable-track
    height: 0
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

  &::-webkit-slider-thumb
    width: calc(var(--pin-size) * 1px)
    height: calc(var(--pin-size) * 1px)
    border-radius: 50%
    background-color: var(--color-red)
    cursor: pointer
    transition: transform 0.2s
    transform: translateY(-50%)
    pointer-events: auto
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

    &:hover
      transform: translateY(-50%) scale(1.1)
