@use '~_css/ds-2023/fonts' as fonts

.cardInfoMeta
  @include fonts.font-s
  color: var(--color-grey-59)

  &::first-letter
    text-transform: uppercase

  & > *:not(:last-child)::after
    content: ''
    margin-left: 6px
    margin-right: 6px
    border-radius: 50%
    width: 3px
    display: inline-block
    background: currentColor
    height: 3px
    vertical-align: middle
