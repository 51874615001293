.horizontalMarketingSlider
  display: flex
  align-items: center

.left.left
  position: absolute
  
.control
  border: 1px solid var(--color-grey-88)
  z-index: 2
  width: 32px
  height: 70px
  border-radius: 12px
  margin-top: 8px
  display: flex
  align-items: center
  justify-content: center
  background-color: white
  cursor: pointer
  transition: all 0.2s ease-in-out

  &:hover
    border: 1px solid transparent
    box-shadow: inset 0px 0px 8px var(--color-black-15)

  &:active
    border: 1px solid transparent
    background-color: var(--color-grey-95)
    box-shadow: inset 0px 0px 8px var(--color-black-15)

  &.hide
    visibility: hidden
