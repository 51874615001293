.installmentPlanTerms
  position: relative
  padding: 64px 0
  color: white

  &::before
    content: ''
    position: absolute
    top: 0
    z-index: 1
    display: block
    width: 100%
    height: 100%
    background-color: #0a0a0a
