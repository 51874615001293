@use '~_css/ds-2023/fonts' as fonts

.form
  display: flex
  flex-direction: column

.helpText
  @include fonts.font-m
  color: var(--color-grey-40-92)

.back
  color: var(--color-grey-59)
  cursor: pointer
  text-decoration: underline

.flexRow
  display: flex
  flex-wrap: nowrap
  gap: 16px

.flexColumn
  display: flex
  flex: 1 1 100%
  gap: 8px
  flex-direction: column

.fields
  margin-top: 36px
  margin-bottom: 36px

.field
  @include fonts.font-m
  display: flex
  flex-direction: column
  gap: 8px

  & + &
    margin-top: 24px

.required::after
  content: ' *'
  color: var(--color-red)

.input
  padding: 14px 16px

.textarea
  outline: none
  padding: 14px 16px
  resize: none
  border-radius: 24px
  border: 1px solid var(--color-grey-88)
  min-height: 132px
  box-sizing: border-box
  transition: box-shadow 0.2s

  &:not(&:disabled)
    &:focus
      cursor: text

    &:hover,
    &:focus
      border: 1px solid var(--color-grey-88)
      box-shadow: inset 0px 0px 8px var(--color-black-15)

.policy
  @include fonts.font-s
  color: var(--color-grey-59)
  text-align: center
  margin-top: 16px

  a
    text-decoration: underline
    text-decoration-color: var(--color-grey-85)
    text-underline-offset: 4px
