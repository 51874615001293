.container
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 4px

.button
  position: relative
  background-color: #ffffff
  z-index: 3

.progress
  position: absolute
  z-index: 2
  margin-left: 52px
  width: 52px
  height: 52px
  transform: rotate(-90deg)

.progressCircle
  animation: progress 12s linear infinite

@keyframes progress
  0%
    stroke-dashoffset: 140

  100%
    stroke-dashoffset: 0
