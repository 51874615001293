@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.month
  text-transform: uppercase
  font-weight: bold

.yearSeparator
  @include fonts.font-s
  display: flex
  align-items: center
  width: 28px
  box-sizing: border-box

  position: sticky
  left: 0
  z-index: 1
  background: white

  color: var(--color-grey-59)
  transform: rotate(180deg)
  writing-mode: vertical-rl

  &::after
    content: ''
    top: 0
    bottom: 0
    position: absolute
    left: 4px
    width: 1px
    background: var(--color-grey-88)
