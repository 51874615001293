@use '~_css/ds-2023/mixins' as mixins

.container
  @include mixins.no-scrollbar
  overflow-y: scroll
  max-height: var(--window-inner-height, 100dvh)

.popup
  display: flex
  flex-direction: column
  // overflow: hidden
  box-sizing: border-box
  background-color: white
  border-top-left-radius: 24px
  border-top-right-radius: 24px
  margin-top: 64px
  min-height: calc(var(--window-inner-height, 100dvh) - 24px)
  width: 100vw
