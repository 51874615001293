.popup
  width: 840px
  height: 510px

.close
  position: absolute
  top: 24px
  right: 24px
  color: var(--color-grey-95)
  cursor: pointer
