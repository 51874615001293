@use '~_css/ds-2023/fonts' as fonts

.quickSearchItemInfo
  display: flex
  flex-direction: column
  gap: 2px
  overflow: auto

  .name
    @include fonts.font-m

  .title
    display: flex
    gap: 4px

    .name
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

  .meta
    @include fonts.font-s
    color: var(--color-grey-45)
