@use '~_css/ds-2023/mixins' as mixins

:root
  --page-column: 296px
  --page-column-gap: 16px

  --page-min-size-inner: calc(var(--page-column) * var(--page-columns-count) + var(--page-column-gap) * (var(--page-columns-count) - 1))
  --page-min-size-outer: calc(var(--page-side-paddings) + var(--page-min-size-inner) + var(--page-side-paddings))
  --page-max-size-inner: calc(var(--page-max-size-outer) - var(--page-side-paddings) * 2)

  --scrollbar-width: calc(var(--_scrollbar-width, 15px) * 1px)

  --max-content-width-in-viewport: calc(100vw - (var(--page-side-paddings) * 2) - var(--scrollbar-width))
  --max-content-height-in-viewport: calc(100vh - (var(--page-side-paddings) * 2) - var(--scrollbar-width))

  @include mixins.on-narrow
    --page-side-paddings: 20px
    --page-columns-count: 3
    --page-max-size-outer: 1280px

  @include mixins.on-normal
    --page-side-paddings: 24px
    --page-columns-count: 4
    --page-max-size-outer: 1592px

  @include mixins.on-wide
    --page-side-paddings: 24px
    --page-columns-count: 5
    --page-max-size-outer: 1676px
