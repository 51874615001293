@use '~_css/ds-2023/buttons' as buttons

.content
  display: flex
  flex-direction: column
  justify-content: center
  align-self: center
  align-items: center
  height: 100%

.text
  margin-bottom: 24px
  color: #ffffff
  transition: opacity 0.2s

.cancel
  @include buttons.default
  position: absolute
  top: 24px
  right: 24px
  color: #ffffff
  transition: opacity 0.2s

  @media (hover hover)
    &:not(:disabled)
      cursor: pointer

      &:active
        opacity: 0.72

.text:not(.visible),
.cancel:not(.visible)
  opacity: 0
