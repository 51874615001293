@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.monthSeparator
  @include fonts.font-s
  display: flex
  align-items: center
  height: 52px
  width: 28px
  box-sizing: border-box
  padding-top: 9px
  padding-bottom: 8px

  position: sticky
  left: 0
  z-index: 1
  background: white

  color: var(--color-grey-59)
  transform: rotate(180deg)
  writing-mode: vertical-rl

  &::after
    content: ''
    top: 9px
    bottom: 8px
    position: absolute
    left: 4px
    width: 1px
    background: var(--color-grey-88)
