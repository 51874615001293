.bubbleCheckbox
  --bubble-size: 30px
  --color: #EBEBEB
  --padding: 2px

  --width-calc: calc(var(--bubble-size) * 1.9)

  display: flex
  position: relative
  box-sizing: border-box

  width: var(--width-calc)

.input
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  z-index: 1
  border-radius: var(--bubble-size)

  &:checked
    & + .marker::before
      background-color: #21A038

    & + .marker::after
      left: calc(var(--width-calc) - var(--bubble-size) - var(--padding))

  &:not(:disabled)
    cursor: pointer

.marker
  width: 100%
  height: calc(var(--bubble-size) + var(--padding) * 2)
  pointer-events: none

  &::before
    content: ''
    display: block

    width: 100%
    height: 100%

    border-radius: var(--bubble-size)
    background-color: #EBEBEB

    transition: background-color 0.2s

  &::after
    content: ''
    display: block

    position: absolute
    left: var(--padding)
    top: var(--padding)
    bottom: var(--padding)

    width: var(--bubble-size)
    height: var(--bubble-size)

    border-radius: var(--bubble-size)
    background-color: white

    transition: left 0.2s
