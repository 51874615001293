@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.movieSessionsGrid
  display: grid
  gap: 8px
  grid-template-columns: repeat(auto-fill, 95px)

  &.start
    place-content: start

  &.end
    place-content: end

  // @include mixins.on-narrow
  //   grid-template-columns: repeat(3, 95px)

  // @include mixins.on-normal
  //   grid-template-columns: repeat(6, 95px)

  // @include mixins.on-wide
  //   grid-template-columns: repeat(6, 95px)
