@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.featureTileInfo
  display: flex
  flex-direction: column
  align-items: flex-start

  @include mixins.on-narrow
    gap: 6px

  @include mixins.on-normal-wide
    gap: 8px
