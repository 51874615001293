@use '~_css/ds-2023/mixins' as mixins

.articlesSection
  display: grid
  gap: 16px
  grid-auto-flow: dense
  grid-template-columns: repeat(auto-fill, var(--page-column))

  @include mixins.on-normal-wide
    max-width: 1232px

    & > *:nth-child(7)
      display: none

  @include mixins.on-narrow
    & > *:nth-child(3)
      grid-row: 1 / 2
      grid-column: span 2 / -1

    & > *:nth-child(4)
      grid-row: 2 / 3
      grid-column: 1 / span 2
