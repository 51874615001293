@use '~_css/ds-2023/mixins' as mixins

.featureTile
  position: relative
  flex-shrink: 0
  margin-bottom: 6px

  transition: width 0.2s
  border-radius: var(--border-radius)
  background-color: var(--color-grey-88)

  aspect-ratio: 1/1
  isolation: isolate

  scroll-snap-align: center

  @include mixins.on-narrow
    --border-radius: 24px

  @include mixins.on-normal-wide
    --border-radius: 32px

  @include mixins.on-narrow-normal
    width: 37%

  @include mixins.on-wide
    width: calc(100% / 3 - 32px / 3)

.wide
  aspect-ratio: 3 / 2
  width: 529px

  @include mixins.on-1440-plus
    width: 610px

.averageColor
  position: absolute
  top: calc(100% - 1px)
  height: 7px
  z-index: -1
  left: 12px
  right: 12px
  clip-path: inset(-40px 20px 0 20px round 0px 0px 20px 20px)

  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0

  &::before
    background-color: var(--color-grey-85)

  &::after
    background-image: var(--avg)
