@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.objectItem
  position: relative
  display: flex
  flex-direction: column
  gap: 16px

.info
  display: flex
  flex-direction: column
  align-items: flex-start
  flex-grow: 1

.main
  display: flex
  align-items: flex-start
  gap: 16px

.link + [data-img]
  pointer-events: none
