@use '~_css/ds-2023/fonts' as fonts

.creationMember
  display: flex
  flex-direction: column
  width: 158px
  flex-shrink: 0
  align-items: center
  text-align: center

.image
  margin-bottom: 16px

.role
  @include fonts.font-s
  color: var(--color-grey-59)
