.tile
  border-radius: 24px

.image
  object-fit: cover

.content
  display: flex
  flex-direction: column
  justify-content: flex-end

  color: white
  padding: 12px
