@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.scheduleItemScheduleButton
  display: flex
  justify-content: flex-start
  align-items: center
  margin-top: 8px
  gap: 4px
  flex-grow: 1
  z-index: 1
  max-width: max-content
