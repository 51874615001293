@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.embededKassaWidgetSession
  @include buttons.default
  @include buttons.outline

  position: relative
  display: flex
  flex-direction: column
  border-radius: 16px
  padding: 12px
  overflow: hidden
  min-height: 112px

  &:hover
    @include buttons.outline-hover

  &.selected
    @include buttons.shadow
    @include buttons.shadow-hover

    .minPrice
      color: black

.top
  display: flex

.minPrice
  @include fonts.font-s
  color: var(--color-grey-59)
  transition: color 0.2s
  margin-bottom: 6px

.discount
  margin-left: auto
  position: absolute
  right: 6px
  top: 6px

.dateTime
  @include fonts.font-l
  margin-bottom: 4px
  text-align: start

.pushkincard
  position: absolute
  object-fit: contain
  bottom: 0
  right: 0
  height: 54px
  width: 62px

.pushkincard + .place
  padding-right: 58px
  width: 100%
  box-sizing: border-box

.place
  @include fonts.font-s
  white-space: pre-wrap
  text-align: start
  margin-top: auto
  max-height: 36px
  overflow: hidden
