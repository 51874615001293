@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.creationDate
  margin-bottom: 96px

.footer
  position: fixed
  bottom: 18px
  left: 12px
  right: 12px

.vote
  padding: 8px
  border-radius: 44px
  background: white
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-95)

  & [data-like],
  & [data-dislike]
    background: rgba(1, 1, 1, 0.06)
    box-shadow: none
    width: 36px
    height: 36px

.actions.actions.actions
  width: 52px
  height: 52px
  background: white
  box-shadow: inset 0px 0px 0px 1px var(--color-grey-95)
