@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerQr
  grid-area: qr
  display: flex
  align-self: flex-start

.image
  @include mixins.on-narrow
    width: 132px
    height: 132px

  @include mixins.on-normal-wide
    width: 187px
    height: 187px
