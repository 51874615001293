@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.widgetContentPages
  display: flex
  flex-direction: column
  position: relative

  .page
    gap: inherit

    &:not(:last-of-type)
      --is-last-page: 0

    &:last-of-type
      --is-last-page: 1

  &.cardsList
    gap: 36px

    .page
      display: flex
      flex-direction: column

  &.two-column-grid
    gap: 36px 8px

    .page
      display: grid
      grid-template-columns: repeat(2, minmax(0, 1fr))

  &.scheduleItems
    gap: 12px

    .page
      display: flex
      flex-direction: column

  &.tiles
    gap: 12px

    .page
      display: flex
      flex-direction: column

  &.search
    gap: 40px

    .page
      display: flex
      flex-direction: column
