@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.default
  background-color: var(--color-grey-70-20)
  border-radius: 50%

.black-circleless
  color: black

.white-circleless
  color: white

.tooltip
  @include fonts.font-s
  font-family: "Suisse"
  padding: 8px
  box-sizing: border-box
  background-color: white
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14)
  border-radius: 20px
  z-index: 1

.icon
  color: var(--color-grey-40)
  cursor: pointer
  transform: rotate(90deg)

.dense
  margin: 0 -5px

.text
  margin: 10px 10px 12px
  color: var(--color-grey-59)

.badge
  margin-bottom: 4px
  color: black

.simplified
  @include fonts.font-s
  padding: 7px 12px

  &.tooltip
    border-radius: 16px
    background: rgba(26, 26, 26, 0.80)
    color: white

  .text
    color: white
    margin: 0

  .active
    color: var(--color-red)