@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
  
.newYearGiftBanner
  background-color: var(--color-grey-95)
  border-radius: 16px

.bannerRow
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 22px

.texts
  display: flex
  flex-direction: column
  gap: 4px

.text1
  font-family: 'SuisseIntl-Medium'
  @include mixins.on-narrow-normal
    @include fonts.font-h2

  @include mixins.on-wide
    @include fonts.font-h1

.text2
  color: var(--color-grey-59)
  font-size: 16px
  line-height: 20px

.image
  width: 297px
  position: relative
  bottom: 13px
