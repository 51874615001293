@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.tileName
  @include fonts.font-h5
  overflow-wrap: anywhere
  text-shadow: 0 0 1px rgb(0 0 0 / 50%)
  overflow: hidden
  max-height: calc(100% - 24px)
  display: -webkit-box
  -webkit-box-orient: vertical

  @media (max-width: 425px)
    &.short
      -webkit-line-clamp: 7

    &.long
      -webkit-line-clamp: 9

  @media (min-width: 426px)
    &.short
      -webkit-line-clamp: 9

    &.long
      -webkit-line-clamp: 11
