@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.explicitAdInfoButton
  @include buttons.default

  padding: 12px 10px
  border-radius: 22px
  width: 100%
  text-align: left

  &:not(:disabled)
    @include buttons.shadow
