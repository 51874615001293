@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/functions' as functions

.objectMeta
  display: flex
  gap: 40px
  align-items: flex-start
  width: functions.columns(3)

.tabs
  @include fonts.font-s
  display: flex
  align-items: center
  gap: 10px

.tab
  &:not(:first-child:last-child)
    cursor: pointer

  &:not(.active)
    color: var(--color-grey-45)

  &.active
    color: black
