@use '~_css/ds-2023/fonts' as fonts

.date,
.name,
.author,
.description
  margin-left: var(--page-side-paddings)
  margin-right: var(--page-side-paddings)

.date
  @include fonts.font-s
  color: var(--color-grey-59)
  margin-bottom: 6px

.name
  @include fonts.font-h3
  margin-bottom: 16px

.author
  display: flex
  align-items: center
  gap: 16px
  margin-bottom: 36px

.whois
  display: flex
  flex-direction: column
  gap: 2px

.authorName
  @include fonts.font-m-bold

.authorActivity
  @include fonts.font-s
  color: var(--color-grey-59)

.description
  @include fonts.font-ml
  margin-top: 24px
  margin-bottom: 36px

