.icon
  display: flex
  flex-shrink: 0
  user-select: none

.white
  color: white

.black
  color: black

.grey-20
    color: var(--color-grey-20)

.grey-59
    color: var(--color-grey-59)

.grey-85
  color: #D9D9D9
