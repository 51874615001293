.notifications
  position: fixed
  left: 0
  right: 0
  bottom: 0
  z-index: 10000

  display: flex
  flex-direction: column
  align-items: center
  padding-bottom: 20px

  gap: 20px
