@use '~_css/ds-2023/fonts' as fonts

.creationMember
  display: flex
  flex-direction: column
  width: 100px
  flex-shrink: 0
  align-items: center
  text-align: center

.name
  overflow-wrap: anywhere

.image
  margin-bottom: 10px

.role
  @include fonts.font-s
  color: var(--color-grey-59)
