@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.input
  outline: none
  box-sizing: border-box

  height: 48px
  border-radius: 256px
  transition: box-shadow 0.2s
  border: 1px solid var(--color-grey-88)

  &:disabled
    opacity: 0.5
    cursor: not-allowed

  &:not(&:disabled)
    &:focus
      cursor: text

    &:hover,
    &:focus
      border: 1px solid var(--color-grey-88)
      box-shadow: inset 0px 0px 8px var(--color-black-15)
