@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberLoyaltyPromoLabel.sberLoyaltyPromoLabel.sberLoyaltyPromoLabel
  width: min-content
  white-space: nowrap

  &.white
    color: #0C9C0C
    background-color: white

  &.outline
    border: 1px solid var(--color-grey-92)

  &.grey
    color: white
    background-color: var(--color-grey-70-40)
