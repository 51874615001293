@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.userRating
  display: flex
  align-items: center

  &.large
    gap: 24px

  &.medium
    gap: 20px

.buttons
  display: flex
  align-items: center
  gap: 6px
  position: relative
