@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeAutoRenewalChangePopup
  display: flex
  flex-direction: column
  gap: 28px
  background: white
  padding: 36px 44px 36px 44px
  border-radius: 32px
  max-width: 574px
  box-sizing: border-box
