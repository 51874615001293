.promoBanner
  position: relative
  display: flex
  flex-shrink: 0
  flex-direction: column
  overflow: hidden
  cursor: pointer
  width: 402px
  height: 140px
  border-radius: 12px

.img
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  border-radius: 12px
