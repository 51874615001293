.popupView
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  flex-direction: column
  align-items: center
  content-visibility: visible
  contain: content
  &.filled
    .content
      width: 100%
      height: 100%
  &.bottom
    justify-content: flex-end
    width: 100%
    .content
      width: 100%
  &.center
    justify-content: center
  &.visible
    .overlay
      opacity: 1
    &.bottom
      .content
        transform: translateY(0%)
    &.center
      .content
        transform: scale(100%)
  &:not(.visible)
    pointer-events: none
    .overlay
      opacity: 0
  &:not(.visible).bottom
    .content
      transform: translateY(100%)
  &:not(.visible).center
    .content
      transform: scale(0)
.content
  position: absolute
  transition: transform 0.2s ease-in-out
.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.48)
  transition: opacity 0.2s ease-in-out
  &.blured
    backdrop-filter: blur(5px)
