.sidebar
  width: 300px
  height: 372px
  display: flex
  flex-direction: column
  align-items: center
  gap: 12px

.skeletonControls
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 4px
