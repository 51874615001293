.installmentPlanTerm
  position: relative
  z-index: 2
  width: 280px
  height: 267px
  border-radius: 16px
  background-color: rgba(196,196,196,0.1)
  pointer-events: none
  user-select: none

  img
    width: 100%
    height: 100%
