@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.articlesListTag
  display: flex
  flex-direction: column
  align-items: center

  width: 130px

  transition: 0.2s
  cursor: pointer

  &:hover .img img
    transform: scale(1.1)

  &.selected
    .img
      --selected-border-spread: 2px

  &:hover
    .img
      --hovered-border-blur: 8px

      background-color: var(--color-grey-92)

.img
  position: relative

  width: 130px
  height: 130px

  border-radius: 50%
  box-sizing: border-box
  overflow: hidden

  background-color: var(--color-grey-95)
  transition: inherit

  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    border-radius: inherit
    transition: inherit
    pointer-events: none
    box-shadow: 0px 0px 0px var(--selected-border-spread, 0) var(--color-red) inset, 0px 0px var(--hovered-border-blur, 0) 0px rgba(0, 0, 0, 0.15) inset

  img
    border-radius: inherit
    object-fit: cover
    transition: inherit

  &:not(.visible) > img
    opacity: 0

.name
  @include fonts.font-s
  margin-top: 16px
  text-align: center
  transition: opacity 0.2s

  &:not(.visible)
    opacity: 0
