@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.explicitAdInfoBadge
  @include fonts.font-custom-ad-badge-8-10
  padding: 4px
  box-sizing: border-box
  border-radius: 40px

  &.default
    background-color: var(--color-grey-85-34)
    color: var(--color-grey-45)

  &.bright
    background-color: var(--color-grey-45-34)
    color: var(--color-grey-70-90)