.promoBanner
  position: relative
  display: flex
  flex-shrink: 0
  flex-direction: column
  overflow: hidden
  border-radius: 8px
  background-color: #f0f0f0
  cursor: pointer
  aspect-ratio: 267 / 102

  &::before,
  &::after
    content: ''
    position: absolute
    top: calc(50% - 10px)
    display: block
    width: 20px
    height: 20px
    background-color: white
    z-index: 1
    border-radius: 50%
    pointer-events: none

  &::before
    left: -10px

  &::after
    right: -10px

.img
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
