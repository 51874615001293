@use '~_css/ds-2023/fonts' as fonts

.installmentPlanFAQParagraph
  & + .installmentPlanFAQParagraph
    margin-top: 12px
    padding-top: 12px
    border-top: 1px solid var(--color-grey-88)

  .installmentPlanFAQParagraphTitle
    @include fonts.font-ml-bold
    display: flex
    justify-content: space-between
    align-items: center
    height: 56px
    cursor: pointer

  .installmentPlanFAQParagraphIcon
    color: #04cd3e

  .installmentPlanFAQParagraphContent
    margin-top: 12px
