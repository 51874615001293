.dropdownView
  border-radius: 4px
  background-color: white
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 8px 12px rgba(0, 0, 0, 0.12)

.dropdownViewHeader
  padding: 12px 24px
  border-bottom: 1px solid var(--color-black-12)

.dropdownViewContent
  padding-right: 24px
  padding-left: 24px

  &:first-child
    padding-top: 24px

  &:not(:first-child)
    padding-top: 16px

  &:last-child
    padding-bottom: 24px

  &:not(:last-child)
    padding-bottom: 16px
