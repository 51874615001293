@use '~_css/ds-2023/fonts' as fonts

.red
  color: var(--color-red)

.pageTitle
  @include fonts.font-h2

.pageMeta
  @include fonts.font-s
  color: var(--color-grey-45)

.episodeName
  @include fonts.font-l

.episodeNumber
  @include fonts.font-s
  color: var(--color-grey-45)

.episodeDuration
  @include fonts.font-s

  & > span
    color: var(--color-grey-45)
