.embeddedKassaWidget
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  border-radius: 16px
  aspect-ratio: 16 / 10
  position: relative
  clip-path: content-box
  background-color: #f2f2f2

  & > aw-session
    height: 100%

  &::after
    content: ''
    box-shadow: 0px 0px 8px 0px #00000014 inset
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    pointer-events: none
