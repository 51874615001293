@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/mixins' as mixins

.socialNetworksText
    width: 100%
    display: flex
    align-items: center
    background-color: var(--color-grey-95)
    border-radius: 16px
    box-sizing: border-box

    @include mixins.on-desktop-screen
        justify-content: space-between
        padding: 24px 32px 24px 24px

    @include mixins.on-mobile-screen
        flex-direction: column
        padding: 20px 16px

.text
    @include mixins.on-desktop-screen
        @include fonts.font-ml
        max-width: 497px

    @include mixins.on-mobile-screen
        @include fonts.font-m-paragraph
        text-align: center
        margin-bottom: 14px

.socials
    display: flex
    align-items: center
    gap: 24px
