.sticky
  position: fixed
  right: 0
  left: 0
  transition: 0.2s

  &:not(.hidden)
    overflow: visible
    opacity: 1
    content-visibility: visible

  &.hidden
    overflow: hidden
    opacity: 0
    pointer-events: none
    content-visibility: auto

.top
  top: 0

.bottom
  bottom: 0
