@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

$shadow-top: inset 0px 8px 8px -8px var(--color-black-15)
$shadow-bottom: inset 0px -8px 8px -8px var(--color-black-15)
$shadow-left: inset 8px 0px 8px -8px var(--color-black-15)
$shadow-right: inset -8px 0px 8px -8px var(--color-black-15)

.calendar
  display: flex
  width: 612px
  gap: 24px
  scroll-snap-type: x mandatory

  --day-width: 42px
  --day-height: 44px

.calendarMonth
  position: relative
  z-index: 1

  scroll-snap-align: start

  // content-visibility: auto
  // contain-intrinsic-width: 294px
  // contain-intrinsic-height: 325px

  .title
    margin-bottom: 8px
    height: 32px
    border-bottom: 1px solid var(--color-grey-92)

    button
      @include buttons.default
      @include fonts.font-m-bold

  .days
    display: grid
    grid-template-columns: repeat(7, var(--day-width))
    grid-auto-rows: var(--day-height)
    row-gap: 4px

.calendarDay
  @include buttons.default
  @include fonts.font-m
  width: var(--day-width)
  height: var(--day-height)
  border-radius: 10px

  &.selected
    position: relative

    &::before
      content: ''
      position: absolute
      z-index: -1
      left: 0
      top: 0
      right: 0
      bottom: 0
      width: var(--day-width)
      height: var(--day-height)
      background-color: var(--color-grey-95)

    &.firstSelected:nth-child(7n),
    &.firstSelected:last-of-type,
    &.lastSelected:nth-child(7n + 1),
    &.lastSelected:nth-child(7):first-of-type,
    &.lastSelected:first-of-type,
    &.firstSelected:nth-child(7n),
    &:first-of-type:nth-child(7):not(.firstSelected):not(.lastSelected)
      &:before
        border-radius: 10px
        box-shadow: $shadow-top, $shadow-bottom, $shadow-left, $shadow-right

    &.firstSelected:not(.lastSelected):not(:nth-child(7n)):not(:last-of-type),
    &:nth-child(7n + 1):not(.lastSelected),
    &:first-of-type
      &:before
        border-radius: 10px 0px 0px 10px
        box-shadow: $shadow-top, $shadow-bottom, $shadow-left

    &.lastSelected:not(.firstSelected):not(:nth-child(7n + 1)):not(:first-of-type),
    &:nth-child(7n):not(.firstSelected),
    &:last-of-type
      &:before
        border-radius: 0px 10px 10px 0px
        box-shadow: $shadow-top, $shadow-bottom, $shadow-right

    &:not(.firstSelected):not(.lastSelected):not(:nth-child(7n + 1)):not(:nth-child(7n)):not(:first-of-type):not(:last-of-type)
      &:before
        box-shadow: $shadow-top, $shadow-bottom

  &:disabled
    opacity: 0.3

  &.holiday
    color: var(--color-red)

  &:not(:disabled)
    transition: all 0.2s ease-in-out

    &:hover
      box-shadow: $shadow-top, $shadow-bottom, $shadow-left, $shadow-right

    &:not(.selected)
      &:hover
        background-color: var(--color-grey-95)

    &.selected
      &:hover
        background-color: var(--color-grey-92)

  &:not(.holiday)
    color: black

.calendarDayStub
  width: var(--day-width)
  height: var(--day-height)
