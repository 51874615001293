@use '~_css/ds-2023/fonts' as fonts

.time
  @include fonts.font-xxl

.place
  color: var(--color-grey-45)

.scheduleItemDetailsPlateHover
  transition: 0.2s

  &:hover
    background-color: var(--color-grey-92)
