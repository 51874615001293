@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.scheduleItemInfoMeta
  &.s
    @include fonts.font-s

  &.ml
    @include fonts.font-ml

  &.xl
    @include fonts.font-xl

  &.grey-59
    color: var(--color-grey-59)
