@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.adaptivePopupView
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex

  &.visible
    .overlay
      opacity: 1

    @include mixins.on-desktop-screen
      .content
        opacity: 1

    @include mixins.on-mobile-screen
      .content
        transform: translateY(0%)

  &:not(.visible)
    .overlay
      opacity: 0

    @include mixins.on-desktop-screen
      .content
        opacity: 0

    @include mixins.on-mobile-screen
      .content
        transform: translateY(100%)

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: var(--color-grey-10-65)
  backdrop-filter: blur(5px)
  transition: opacity 0.2s ease-in-out

.content
  z-index: 1

  @include mixins.on-desktop-screen
    margin: auto
    transition: opacity 0.2s ease-in-out

  @include mixins.on-mobile-screen
    width: 100%
    align-self: flex-end
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out
