.underlayImageContent
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 2
  pointer-events: none

  & button,
  & a
    pointer-events: auto
