@use '~_css/ds-2023/fonts' as fonts

.featureTileInfoMeta
  @include fonts.font-s

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 4
