@use '~_css/ds-2023/fonts' as fonts

.error
  min-height: calc(var(--window-inner-height, 100dvh) - 250px)
  display: flex
  align-items: center
  justify-content: center
  margin-left: var(--page-side-paddings)
  margin-right: var(--page-side-paddings)
  text-align: center

.text
  @include fonts.font-h3

.suggest
  @include fonts.font-s
