.tile
  border-radius: 24px
  height: 296px
  overflow: hidden

  &.wide
    grid-column: span 2 / auto

  &.flexible
    aspect-ratio: 1
    height: auto

.image
  object-fit: cover
  border-radius: 24px

.content
  display: flex
  flex-direction: column
  justify-content: flex-end

  color: white
  padding: 16px

.tileLink
  &::after
    content: ''
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.15) 30%, rgba(0,0,0,0.4) 100%)
