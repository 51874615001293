@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.profileUserCard
  box-sizing: border-box
  height: 140px
  padding: 16px
  background-color: var(--color-grey-95)
  border-radius: 24px
  display: flex
  align-items: center
  gap: 20px

.nameBlock
  display: flex
  flex-direction: column
  gap: 4px
  box-sizing: border-box
  flex: 1

  .name
    max-height: 72px
    @include fonts.font-h2

  .prime
    @include fonts.font-s
    color: var(--color-grey-70-90)
    display: flex
    align-items: center
    gap: 8px

.settings
  align-self: flex-end
