@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.afishaBadgeWithTooltip
  pointer-events: auto
  cursor: pointer

.afishaBadgeWithoutTooltip
  pointer-events: none
  
