@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.modal
  position: relative
  display: flex
  flex-direction: column
  background-color: white
  overflow: hidden
  width: 100vw

  &.small
    max-height: calc(var(--window-inner-height, 100dvh) - 24px)
    border-top-left-radius: 24px
    border-top-right-radius: 24px

  &.medium
    height: calc(var(--window-inner-height, 100dvh) - 24px)
    border-top-left-radius: 24px
    border-top-right-radius: 24px

  &.large
    height: calc(var(--window-inner-height, 100dvh))

  &.small-floating
    max-height: calc(var(--window-inner-height, 100dvh) - 24px)
    border-radius: 24px
    width: calc(100vw - 24px)
    margin-bottom: 20px
    margin-left: auto
    margin-right: auto

.header
  display: flex
  gap: 12px
  padding: 16px
  justify-content: space-between

.title
  @include fonts.font-h3
