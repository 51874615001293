@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.posterImage
  position: relative

.rating
  position: absolute
  top: 6px
  left: 6px
  z-index: 1
  pointer-events: none

.buttons
  display: flex
  position: absolute
  right: 8px
  bottom: 8px
  z-index: 1
  gap: 4px
