@use '~_css/ds-2023/fonts' as fonts

@keyframes marquee
  0%
    transform: translateX(0)

  100%
    transform: translateX(calc(var(--marquee-width) * -1px))

.inlinePlaceLabel
  @include fonts.font-s
  font-weight: 600
  white-space: nowrap

  display: inline-flex
  flex-wrap: nowrap
  align-items: center
  gap: 3px

  padding: 0 5px
  border-radius: 4px

  background-color: var(--color-grey-95)

.middle
  vertical-align: middle

.top
  vertical-align: text-top

.bottom
  vertical-align: text-bottom

.textContainer
  overflow: hidden

.text
  white-space: nowrap

.marquee
  transform-origin: center right
  animation: marquee calc(var(--marquee-width) * 1s / 10) infinite linear alternate
