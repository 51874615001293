@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.profileHeader
  display: grid
  grid-template-columns: 1fr 1fr 1fr

.user
  position: relative
  grid-column: 1 / span 3
  display: flex
  align-items: center

.nameBlock
  display: flex
  flex-direction: column
  margin-left: 16px
  margin-right: auto
  gap: 4px
  box-sizing: border-box

  .name
    @include fonts.font-h5
    max-height: 44px

  .prime
    @include fonts.font-s
    display: flex
    align-items: center
    color: var(--color-grey-70)
    gap: 5px

.exit
  margin-left: 12px
  align-self: flex-end

.user,
.counter
  padding: 10px
  border-radius: 20px
  background: var(--color-grey-95)

.counter
  display: flex
  flex-direction: column
  cursor: pointer

.count
  @include fonts.font-h3

.label
  @include fonts.font-s
  margin-top: 6px
  color: var(--color-grey-45)
