@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.footerLinksSection
  display: flex
  flex-direction: column
  gap: 48px

.group
  position: relative
  display: flex
  flex-wrap: wrap
  gap: 12px 16px

  & + &::before
    content: ''
    display: block
    position: absolute
    top: -24px
    left: 0
    right: 0
    height: 1px
    background-color: var(--color-grey-88)
    pointer-events: none

.link
  color: var(--color-grey-45)
