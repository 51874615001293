@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeLabel
  @include fonts.font-s
  display: flex
  align-items: center
  justify-content: center
  gap: 5px
  height: 34px
  width: 115px
  box-sizing: border-box
  border-radius: 20px
  cursor: default
  user-select: none

  &.grey-filled
    color: white
    background-color: var(--color-grey-70-40)

  &.white-outline
    color: black
    border: 1px solid var(--color-grey-92)
