@use '~_css/ds-2023/fonts' as fonts

.createUserEventBanner
  display: flex
  flex-direction: column
  position: relative
  align-items: flex-start
  gap: 16px
  background: linear-gradient(150deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.8) 50%, rgba(203,224,146,0.75) 100%)
  border-radius: 36px
  padding: 36px 24px 36px 40px
  overflow: hidden

.header
  display: flex
  justify-content: space-between
  width: 100%
  @include fonts.font-h2
  align-items: flex-start

.text
  width: 60%
  color: var(--color-grey-45)

.close
  all: initial
  cursor: pointer
  z-index: 100

.crystal
  position: absolute
  bottom: 0
  right: 0
  height: 100%
  & > img
    width: 100%
    height: 100%