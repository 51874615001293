@keyframes spinner
  0%
    box-shadow: calc(var(--size) * -1) calc(var(--size) * -1) 0 currentColor, var(--size) calc(var(--size) * -1) 0 currentColor, calc(var(--size) * -1) var(--size) 0 currentColor

  25%
    box-shadow: calc(var(--size) * -1) calc(var(--size) * -1) 0 currentColor, var(--size) var(--size) 0 currentColor, calc(var(--size) * -1) var(--size) 0 currentColor

  50%
    box-shadow: var(--size) calc(var(--size) * -1) 0 currentColor, var(--size) var(--size) 0 currentColor, calc(var(--size) * -1) var(--size) 0 currentColor

  75%
    box-shadow: var(--size) calc(var(--size) * -1) 0 currentColor, var(--size) var(--size) 0 currentColor, calc(var(--size) * -1) calc(var(--size) * -1) 0 currentColor

  100%
    box-shadow: var(--size) calc(var(--size) * -1) 0 currentColor, calc(var(--size) * -1) var(--size) 0 currentColor, calc(var(--size) * -1) calc(var(--size) * -1) 0 currentColor

.spinner
  display: flex
  width: calc(var(--size) * 3)
  height: calc(var(--size) * 3)

  &.small
    --size: 6px

  &.normal
    --size: 10px

  &.big
    --size: 16px

  &.blue
    color: #0050ff

  &.white
    color: white

  &.red
    color: var(--color-red)

  &.currentColor
    color: currentColor

  &.variable
    color: var(--spinner-color)

  &::before
    margin: var(--size) !important
    width: var(--size)
    height: var(--size)
    border-radius: 50%
    content: ''
    animation: spinner 1.3s infinite !important
    pointer-events: none
