@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/functions' as functions

.pageBlockNarrowNormal
  .content
    display: flex
    flex-direction: column

  .side
    display: flex
    flex-direction: column

  .content:not(:first-child:last-child)
    @include mixins.on-narrow-wide
      width: 100%

    @include mixins.on-normal
      width: functions.columns("var(--page-columns-count) - 1")

    & + .side
      transform: translateX(max(var(--page-column-gap), calc(((100vw - var(--page-min-size-inner)) / 2) - var(--page-side-paddings))))
      width: var(--page-column)

      @include mixins.on-narrow-wide
        display: none

  .content:first-child:last-child
    width: 100%
