@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.objectCoverBottomLeft
  position: absolute
  bottom: calc(12px + var(--parallax-offset))
  left: 12px
  display: flex
  align-items: center
  gap: 8px
