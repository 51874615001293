
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

@keyframes marquee
    0%
        transform: translateX(0)

    100%
        transform: translateX(calc(var(--marquee-width) * -1px))

.label
    @include buttons.transparent

    overflow: hidden

    display: flex
    flex-wrap: nowrap
    align-items: center
    gap: 4px

    &.large
        @include fonts.font-m
        border-radius: 36px
        padding: 14px 20px

    &.medium
        @include fonts.font-s
        border-radius: 28px
        padding: 11px 12px

    &.small
        @include fonts.font-s
        border-radius: 16px
        padding: 7px 10px
        border: 1px solid var(--color-white-10)

.textContainer
    overflow: hidden

.text
    white-space: nowrap

.marquee
    transform-origin: center right
    animation: marquee calc(var(--marquee-width) * 1s / 10) infinite linear alternate
