@use '~_css/ds-2023/fonts' as fonts

.userReviewFormAuthForcingTextarea
  @include fonts.font-m-paragraph
  border: none
  outline: none
  flex-shrink: 0
  overflow: hidden
  display: flex
  flex-direction: column
  background: white
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1)
  border-radius: 24px
  height: 142px
  box-sizing: border-box
  padding: 20px 24px
  cursor: text
  color: var(--color-grey-45)
  margin-bottom: 24px
