@use '~_css/ds-2023/mixins' as mixins

.scrollLayerContainer
  @include mixins.no-scrollbar
  display: flex

  overflow-x: scroll
  overflow-y: hidden
  width: 100%

  contain: layout

  &.scrollSnap
    scroll-snap-type: x mandatory

    & > [data-scroll-layer-content] > *
      scroll-snap-align: center

    &:not(.overScroll)
      & > [data-scroll-layer-content] > *
        scroll-snap-stop: always
