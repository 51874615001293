@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

$shadow-top: inset 0px 8px 8px -8px var(--color-black-15)
$shadow-bottom: inset 0px -8px 8px -8px var(--color-black-15)
$shadow-left: inset 8px 0px 8px -8px var(--color-black-15)
$shadow-right: inset -8px 0px 8px -8px var(--color-black-15)

.horizontalCalendarFilterDayButton
  @include buttons.default
  border-radius: 16px

  &:disabled
    & > .content
      & > .dayOfWeek,
      & > .date
        opacity: 0.4

  .content
    position: relative
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    border-radius: 16px
    padding: 8px 14px 12px
    width: 60px
    height: 70px

    content-visibility: visible
    contain-intrinsic-width: 60px
    contain-intrinsic-height: 70px

    .dayOfWeek
      @include fonts.font-s
      align-self: flex-start
      color: var(--color-grey-59)

    .date
      @include fonts.font-xl-numbers
      text-align: center

  .garland
    z-index: 1

    &::before
      content: url('/img/desktop-2023/calendar-garland/garland.svg')
      width: 100%
      height: 100%
      position: absolute
      transform: translateX(-5px) translateY(-30px)
      z-index: 1
      pointer-events: none

  .reversedGarland
    z-index: 1

    &::before
      content: url('/img/desktop-2023/calendar-garland/garland.svg')
      width: 100%
      height: 100%
      position: absolute
      transform: translateX(-5px) translateY(-30px)
      right: 1040%
      z-index: 1
      pointer-events: none

  &.holiday
    .content
      .dayOfWeek
        color: #FF3118


  &:not(:disabled)
    .content
      transition: box-shadow 0.1s, background-color 0.2s
      cursor: pointer

      &:hover
        box-shadow: inset 0 0 8px var(--color-black-15)

    &:active
      .content
        background-color: var(--color-grey-95)
        box-shadow: inset 0 0 8px var(--color-black-15)

  .resetButton
    @include buttons.default
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    width: 24px
    height: 24px
    top: -8px
    right: -8px
    border-radius: 50%
    background-color: #000000
    z-index: 2

.horizontalCalendarFilterDayButton
  & + &
    margin-left: 6px

  & + .horizontalCalendarFilterSeparator
    padding-left: 6px

  &.selected
    position: relative

    .content
      &:hover
        background-color: var(--color-grey-92)

    &::before
      position: absolute
      display: block
      content: ''
      top: 0
      right: 0
      bottom: 0
      background-color: var(--color-grey-95)

    &.firstSelected.lastSelected
      &::before
        left: 0
        border-radius: 16px
        box-shadow: $shadow-top, $shadow-bottom, $shadow-left, $shadow-right

    &.firstSelected:not(.lastSelected)
      &::before
        left: 0
        border-radius: 16px 0 0 16px
        box-shadow: $shadow-top, $shadow-bottom, $shadow-left

    &.lastSelected:not(.firstSelected)
      &::before
        border-radius: 0 16px 16px 0
        box-shadow: $shadow-top, $shadow-bottom, $shadow-right

    &:not(.firstSelected):not(.lastSelected)
      &::before
        box-shadow: $shadow-top, $shadow-bottom

    &:not(.firstSelected)
      &::before
        left: -6px

  &:not(.selected)
    .content
      background-color: white

.horizontalCalendarFilterMonthButton
  @include buttons.default
  @include buttons.white
  @include fonts.font-s-caps
  font-weight: 500
  padding: 12px 14px
  border-radius: 24px

  &:disabled
    &.selected
      @include buttons.shadow
      background-color: var(--color-grey-95)

    &:not(.selected)
      @include buttons.white-disabled

  &:not(:disabled)
    &:hover
      @include buttons.white-hover

.horizontalCalendarFilterMonthButton
  & + &,
  & + .horizontalCalendarFilterSeparator
    margin-left: 6px

.horizontalCalendarFilterSeparator
  position: relative
  width: 24px
  height: 70px
  flex-shrink: 0

  &.selected
    background-color: var(--color-grey-95)
    box-shadow: $shadow-top, $shadow-bottom

  &::after
    content: ''
    position: absolute
    width: 1px
    height: 52px
    right: 2px
    top: 9px
    background-color: var(--color-grey-88)

  .text
    @include fonts.font-s
    bottom: 12px
    right: 5px
    position: absolute
    height: 49px
    width: 17px
    color: var(--color-grey-59)
    writing-mode: vertical-rl
    transform: rotate(180deg)

.horizontalCalendarFilterSeparator
  & + .horizontalCalendarFilterDayButton,
  & + .horizontalCalendarFilterMonthButton
    margin-left: 6px
