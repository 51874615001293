@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.objectMetaSection
  display: flex
  flex-direction: column
  gap: 8px

.title
  @include fonts.font-h2
  white-space: pre-wrap

.text
  @include fonts.font-m-paragraph
  white-space: pre-wrap

.shittyTitle
  @include fonts.font-h3
  white-space: pre-wrap
  margin-top: 16px
  margin-bottom: 8px
