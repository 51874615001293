@use '~_css/ds-2023/fonts' as fonts

.image
  width: 100%

  & img
    width: inherit
    background-color: var(--color-grey-95)
    object-fit: contain

.link
  display: flex

.thingName
  @include fonts.font-ml

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
