@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeAutoRenewalChangePopup
  display: flex
  flex-direction: column
  gap: 24px
  background: white
  padding: 24px 16px
  border-top-left-radius: 24px
  border-top-right-radius: 24px
