@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.widgetContentOverlayLoader
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 10
  top: calc(var(--page-side-paddings) * -1)
  bottom: calc(var(--page-side-paddings) * -1)
  left: calc(var(--page-side-paddings) * -1)
  right: calc(var(--page-side-paddings) * -1)
  transition: backdropfilter 0.2s, opacity 0.2s

  &:not(.visible)
    pointer-events: none
    opacity: 0

  &.visible
    pointer-events: initial
    opacity: 1
    backdrop-filter: blur(5px)
