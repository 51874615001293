@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.okkoPromotionBanner
  display: flex
  background-color: var(--color-grey-95)
  border-radius: 16px
  box-sizing: border-box
  justify-content: space-between
  position: relative
  padding: 20px 0px 20px 20px
  min-height: 130px
  transition: 0.2s

  &:hover
    background-color: var(--color-grey-92)

.badge
  position: absolute
  left: 8px
  top: 8px

.shit
  overflow: hidden
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  border-radius: inherit
  pointer-events: none

  &::before
    content: ""
    position: absolute
    right: 30px
    bottom: -90px
    background-color: #821EF6
    border-radius: 50%
    width: 270px
    height: 270px
    filter: blur(42px)
    transform: translate(50%, 50%)
    opacity: 0.3

.texts
  display: flex
  flex-direction: column
  justify-content: center
  gap: 8px
  margin-right: 154px
  z-index: 1
  margin-top: 10px

.text1
  @include fonts.font-h3

.text2
  color: var(--color-grey-40)

.image
  position: absolute
  bottom: 0
  width: 198px
  right: 0px
  pointer-events: none
