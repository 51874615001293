@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.modalContent
  position: relative
  display: flex
  flex-direction: column
  flex-grow: 1
  overflow: auto
  overscroll-behavior: contain
  contain: layout style
  padding-bottom: 40px
  margin-top: 8px

  will-change: filter

  &.loading
    transition: filter 0.2s 0.3s
    filter: blur(3px)

  &:not(:last-child)
    padding-bottom: 88px
