@use '~_css/ds-2023/mixins' as mixins

.sberBanner
  background-repeat: no-repeat
  background-size: 100% 200px
  min-height: 200px
  
.premier
  @include mixins.on-narrow
    background-image: url('/img/common/sberpremier/plans_desk_920.jpg')

  @include mixins.on-normal-wide
    background-image: url('/img/common/sberpremier/plans_desk_1280.jpg')

.perviy
  @include mixins.on-narrow
    background-image: url('/img/common/sberperviy/perviy_desk_920.jpg')

  @include mixins.on-normal-wide
    background-image: url('/img/common/sberperviy/perviy_desk_1280.jpg')
