.filterButton.filterButton
  white-space: pre
  padding-left: 12px
  padding-right: 12px
  max-height: 40px

  &.active
    background-color: var(--color-grey-95)

  &[data-badge]::after
    content: ' ' attr(data-badge)
    color: var(--color-grey-59)

.arrow
  margin: -1px
  transform: rotate(90deg)
