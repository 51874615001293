@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeNewSubscriptionBanner
  @include fonts.font-m

  display: flex
  align-items: center
  height: 92px

  padding: 0 18px 0 26px

  border: 1px solid var(--color-grey-88)
  box-sizing: border-box
  border-radius: 20px

.text
  margin-left: 38px
  max-width: 322px
  color: var(--color-grey-45)

.button.button
  margin-left: auto
  color: white
  background-color: #21A038
