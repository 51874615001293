@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/functions' as functions

.searchResultItem
  position: relative
  display: flex
  align-items: flex-start
  gap: 20px

  & + &::before,
  &:last-child::after
    content: ''
    position: absolute
    left: 0
    right: 42px
    height: 1px
    background-color: var(--color-grey-88)
    width: 100%
    pointer-events: none

  & + &::before
    top: -20px

  &:last-child::after
    bottom: -20px
    height: functions.if_false(--is-last-page, 1px)
