@use '~_css/ds-2023/fonts' as fonts
  
.userProfile
  display: flex
  align-items: center
  font-family: 'Suisse'
  gap: 16px

.infoColumn
  display: flex
  flex-direction: column

.name
  @include fonts.font-ml

.email
  @include fonts.font-s
  color: var(--color-grey-59)
  
.link
  position: relative
  padding: 16px var(--page-side-paddings)
  contain-intrinsic-height: 56px

  & + &::before
    content: ''
    height: 1px
    position: absolute
    top: 0
    left: var(--page-side-paddings)
    right: var(--page-side-paddings)
    background-color: var(--color-grey-92)
