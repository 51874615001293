@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeNewSubscriptionBanner
  display: flex
  flex-direction: column

  padding: 16px 14px 14px

  border: 1px solid var(--color-grey-88)
  box-sizing: border-box
  border-radius: 20px

.logo
  margin-bottom: 12px

.text
  @include fonts.font-s
  margin-bottom: 16px
  max-width: 265px
  color: var(--color-grey-45)

.button.button
  color: white
  background-color: #21A038
