@keyframes fadeIn
  from
    opacity: 0

  to
    opacity: 1

.filterDropdownContainer
  position: absolute
  z-index: 1000
  animation: fadeIn 0.2s forwards
