@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.materialMeta
  @include fonts.font-s
  margin-top: 12px
  color: var(--color-grey-59)

  & > span:not(:last-child)::after
    content: '・'
