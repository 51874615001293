@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.saleBadge
  @include fonts.font-custom-sale-13-16
  display: flex
  color: black
  align-items: center
  justify-content: center
  box-sizing: border-box
  text-align: center

  &.xxs
    @include fonts.font-custom-sale-10-13
    height: 15px
    min-width: 35px
    border-radius: 8px
    padding: 1.5px 1px 1.5px 0px

  &.xs
    height: 22px
    min-width: 52px
    border-radius: 6px
    padding: 3px 4px
    &.single
      min-width: 27px

  &.cta-s
    height: 32px
    min-width: 50px
    border-radius: 40px
    padding: 8px 6px 9px 6px
    &.single
      min-width: 32px

  &.cta-l
    height: 48px
    min-width: 48px
    border-radius: 30px
    padding: 20px 4px 20px 4px
    &.single
      @include fonts.font-h5

  &.cta-xl
    height: 56px
    min-width: 56px
    border-radius: 30px
    padding: 20px 8px 20px 8px
    &.single
      @include fonts.font-h5
