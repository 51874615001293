@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

$counterWidth: 91px

.gallery
  display: flex
  flex-direction: column
  width: 100vw
  height: 100%
  gap: 20px
  padding-top: 16px
  padding-bottom: 16px
  box-sizing: border-box

  background-color: rgba(0, 0, 0, 0.65)

.top
  display: flex
  justify-content: space-between
  gap: 24px
  margin-left: 16px
  margin-right: 16px

.copyright
  @include fonts.font-s
  color: var(--color-grey-45)

.close
  margin-left: auto
  color: white
  flex-shrink: 0

.slides
  display: flex
  flex-grow: 1

.slidesContent
  align-items: center

.photoSlide
  width: 100vw
  min-width: 100vw
  height: 100%
  object-fit: contain

.counter
  flex-shrink: 0
  min-width: $counterWidth
  box-sizing: border-box
  padding: 10px 24px
  border-radius: 256px
  background: rgba(77, 77, 77, 0.50)
  margin-top: auto
  margin-left: auto
  margin-right: auto
  color: white
  text-align: center

.previewsContainer
  flex-shrink: 0

.previewsContent
  display: flex
  align-items: center
  height: 64px

.preview
  position: relative
  overflow: hidden
  height: 44px
  border-radius: 4px
  background-color: var(--color-grey-95)

  &.current
    box-shadow: 0px 0px 0px 2px var(--color-grey-95)

  &.playable
    &::before
      content: url(/img/svg/__imported__/i-icon--play.svg)
      filter: invert(100%)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1

.landscape
  &.gallery
    padding: 0

  .top
    position: absolute
    right: 0
    z-index: 2
    margin: 24px

  .counter
    position: absolute
    bottom: 15px
    left: calc(50% - ($counterWidth / 2))
    pointer-events: none