.filterButton.filterButton
  white-space: pre
  padding: 11px 14px

  &.active
    background-color: var(--color-grey-95)

  &:not(.active)
    background-color: white

  &[data-count]::after
    content: ' ' attr(data-count)
    color: var(--color-grey-59)
