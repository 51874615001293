@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/mixins' as mixins

.featureTileInfoMeta
  @include fonts.font-m

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical

  @include mixins.on-narrow
    -webkit-line-clamp: 4

  @include mixins.on-normal-wide
    -webkit-line-clamp: 5
