@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.saleLabelBadge
  display: flex
  align-items: center
  border-radius: 6px
  background-color: var(--color-grey-95)

.badge.badge
  border-radius: inherit

.label
  @include fonts.font-s
  margin-left: 4px
  margin-right: 6px
