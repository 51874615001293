@use '~_css/ds-2023/fonts' as fonts
  
.date
  width: 220px
  position: relative 
  border-radius: 16px
  padding: 12px 0px
  box-sizing: border-box
  flex-shrink: 0

.dayAndMonthBlock
  display: flex

.dayOfMonth
  @include fonts.font-n2

.dayOfWeek
  @include fonts.font-s-caps
  margin-top: 4px

  &:not(.dayOff)
    color: var(--color-grey-45)

  &.dayOff
    color: var(--color-red)

.month
  @include fonts.font-xl
  margin-left: 8px
