.card
  display: grid
  grid-template-columns: repeat(auto-fill, var(--page-column))
  gap: 32px 16px

.poster
  display: grid
  grid-template-columns: repeat(auto-fill, 140px)
  gap: 16px

.list
  display: flex
  flex-direction: column
  gap: 20px

.container
  display: flex
  flex-wrap: wrap
  gap: 6px 4px
