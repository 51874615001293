@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.widgetContentContainer
  display: flex
  flex-direction: column
  gap: 24px

  &.sidePaddings
    padding-left: var(--page-side-paddings)
    padding-right: var(--page-side-paddings)
