@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.searchInput
  position: relative
  width: 100%

.input
  padding: 0 calc(10px + 24px + 19px) 0 calc(16px + 24px + 10px)
  background: 16px/24px no-repeat url(/img/svg/__imported__/i-icon--search-medium.svg), white
  width: 100%

.reset
  background-color: transparent
  cursor: pointer
  border: none
  position: absolute
  transition: opacity 0.2s, color 0.2s
  right: 19px
  top: 50%
  transform: translateY(-50%)
  color: var(--color-grey-59)

  &:hover
    color: black

  .visible
    opacity: 1

  &:not(.visible)
    opacity: 0
