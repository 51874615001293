@use '~_css/ds-2023/fonts' as fonts

.metaInfoGridField
  display: flex
  flex-direction: column
  gap: 4px

.name
  @include fonts.font-s
  color: var(--color-grey-59)

.value
  @include fonts.font-m

.redValue
  color: var(--color-red)