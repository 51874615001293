@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.meta
  @include fonts.font-s
  font-family: 'Suisse', Helvetica, Arial, sans-serif
  letter-spacing: normal
  color: var(--color-grey-59)

.headerBlock
  display: flex
  gap: 16px

.titleBlock
  display: flex
  flex-direction: column
  gap: 8px

.image
  height: fit-content