@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimePopup
  width: 420px
  border-radius: 24px
  background-color: white

  font-size: 18px
  line-height: 26px
  letter-spacing: 0.01em

.close
  position: absolute
  z-index: 10
  top: 16px
  right: 16px
  padding: 8px
  color: black
  cursor: pointer

.content
  position: relative
  display: flex
  flex-direction: column
  margin: auto
  padding: 0 32px 28px

.logo
  margin-top: 34px

.title
  margin-top: 30px

  font-family: 'Afisha Display'
  font-weight: 500
  font-size: 32px
  line-height: 36px
  letter-spacing: -0.01em

  [data-green]
    color: #21A038

  &.big
    font-size: 43px
    line-height: 46px
    letter-spacing: -0.01em

.text1
  margin-top: 6px
  font-size: 15px
  line-height: 20px
  color: #8C8C8C

.text2
  margin-top: 24px
  white-space: pre-wrap

.benefits
  margin-top: 24px

  &[data-title]::before
    content: attr(data-title)
    display: block
    font-weight: bold
    margin-bottom: 6px

.benefit
  list-style-type: disc
  margin-left: 16px

.benefits + .button.button.button
  margin-top: 28px

.button.button.button
  margin-top: 32px
  height: 60px
  background-color: #21A038
  color: white

  font-size: 18px

  &:hover
    color: white

.trial
  font-size: 13px
  line-height: 18px

  margin-top: 12px

.offer
  font-size: 13px
  line-height: 18px

  color: #8C8C8C
  margin-top: 12px
  margin-bottom: -4px
  text-align: center

.offerLink.offerLink.offerLink
  text-decoration: underline
  text-decoration-color: currentColor
  text-underline-offset: 2px
  text-decoration-thickness: 1px
  color: currentColor
