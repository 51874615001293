@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.verdict
  @include fonts.font-m-bold
  line-height: 23px
  white-space: pre-wrap

.text
  @include fonts.font-m-paragraph
  white-space: pre-wrap
  overflow-wrap: anywhere
