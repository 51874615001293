@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.gallery
  position: relative
  display: flex
  flex-direction: column
  padding-top: 24px
  padding-bottom: 24px
  gap: 16px
  flex-grow: 1
  align-items: center
  height: 100vh
  width: 100vw
  box-sizing: border-box
  background-color: rgba(0,0,0,0.8)

.close
  position: absolute
  top: 36px
  right: 36px
  cursor: pointer
  color: white

.currentSlide
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex-grow: 1
  max-height: calc(100vh - 24px - 24px - 16px - 44px - 16px - 64px)
  max-width: calc(100vw - 24px - 24px - 160px)
  width: 100%

  &:hover .control
    opacity: 0.5

.photoSlide
  overflow: hidden

.photo
  display: block
  max-width: 100%
  max-height: 100%
  margin: 0px auto
  border-radius: 24px

.control
  position: absolute
  color: white
  height: 50%
  display: flex
  align-items: center
  justify-content: flex-end
  cursor: pointer
  width: 256px
  transition: 0.2s opacity
  box-sizing: border-box
  padding-right: 24px
  opacity: 0

  &:hover:hover
    opacity: 1

  &.right
    right: 0px
    &.eagle
      width: 156px
      height: 38%

  &.left
    left: 0px
    transform: rotate(180deg)

.counter
  @include fonts.font-l
  background: rgba(77, 77, 77, 0.5)
  border-radius: 30px
  padding: 10px 24px
  color: white
  flex-shrink: 0
  width: 115px
  box-sizing: border-box
  text-align: center

.previews
  @include mixins.no-scrollbar
  display: flex
  overflow: auto
  height: 64px
  gap: 8px
  max-width: 616px
  align-items: center
  overflow-y: hidden
  padding: 2px

.previewContainer
  position: relative
  cursor: pointer
  width: 66px
  height: 44px
  transition: width 0.1s, height 0.1s, box-shadow 0.1s, transform 0.1s
  border-radius: 4px
  background-color: var(--color-grey-95)
  max-width: 90px
  box-sizing: border-box
  flex-shrink: 0
  overflow: hidden

  &.active
    width: 90px
    height: 60px
    box-shadow: 0px 0px 0px 2px var(--color-grey-95)

  .previewImage
    width: 100%
    height: 100%

  &.playable
    &::before
      content: url(/img/svg/__imported__/i-icon--play.svg)
      filter: invert(100%)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1

.copyright
  @include fonts.font-s
  position: absolute
  left: 16px
  bottom: 12px
  color: var(--color-grey-45)
