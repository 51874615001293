.galleryPreview
  display: grid
  gap: 8px

.item
  position: relative
  aspect-ratio: 225 / 149
  cursor: pointer
  border-radius: 16px

  &[data-rest]::after
    content: '+' attr(data-rest)
    position: absolute
    bottom: 0
    right: 0
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    color: white
    font-size: 36px
    line-height: 32px
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.25)
    pointer-events: none

  &.playable
    &::after
      content: url(/img/svg/__imported__/i-icon--play.svg)
      filter: invert(100%)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 1

  & > picture > img
    will-change: auto
