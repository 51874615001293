.container
  margin: 0px auto
  display: flex
  align-items: center
  flex-wrap: nowrap
  gap: 16px
  padding-inline: var(--page-side-paddings)

.pageBlock
  overflow-x: auto
  scrollbar-width: none

.link
  white-space: nowrap
