@use '~_css/ds-2023/fonts' as fonts

.header
  display: flex
  align-items: center
  white-space: pre-wrap
  gap: 32px

.close
  all: initial
  cursor: pointer
  margin-left: auto

.title
  @include fonts.font-h2
