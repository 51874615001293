@use '~_css/ds-2023/buttons' as buttons
@use '~_css/ds-2023/fonts' as fonts

.embededKassaWidgetMore
  @include buttons.default
  @include buttons.outline
  @include fonts.font-ml

  display: flex
  align-items: center
  justify-content: center
  gap: 4px
  border-radius: 16px
  padding: 12px
  color: var(--color-grey-45)

  &:hover
    @include buttons.outline-hover
