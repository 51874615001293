@use '~_css/ds-2023/fonts' as fonts

.createUserEventBanner
  display: flex
  flex-direction: column
  position: relative
  height: 50vw
  justify-content: space-around
  align-items: flex-start
  background: linear-gradient(150deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.8) 50%, rgba(203,224,146,0.75) 100%)
  border-radius: 24px
  padding: 0 16px 8px 24px
  margin: 0 var(--page-side-paddings)
  overflow: hidden

.header
  display: flex
  justify-content: space-between
  @include fonts.font-h3
  align-items: flex-start
  padding-top: 8px

.text
  width: 60%
  @include fonts.font-s
  color: var(--color-grey-40)

.close
  all: initial
  cursor: pointer
  z-index: 100

.crystal
  position: absolute
  bottom: 0
  right: 0
  margin-bottom: -5%
  margin-right: -35%
  width: 90%
  transform: rotate(-16deg)
  & > img
    width: 100%
    height: 100%