@use '~_css/ds-2023/mixins' as mixins
  
.adArticleCardContainer
  display: flex
  position: relative
  flex-direction: column
  flex: 1 1 auto
  scroll-snap-align: center

.articleInfoName
  @include mixins.text-line-clamp(3)
  flex-grow: 1

.articleNameBeforeColon
  font-weight: bold

.articleInfo
  display: flex
  flex-direction: column
  flex: 1 1 auto
  gap: 24px
  padding: 12px
  background-color: #ffffff
  border-radius: 15px

.articleInfoUnderlay
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-end

.articleInfoDate
  font-size: 13px
  color: var(--color-grey-59)
