@use '~_css/ds-2023/buttons' as buttons
@use '~_css/ds-2023/fonts' as fonts

.profileCategoryCard
  box-sizing: border-box
  height: 140px
  padding: 16px 24px 16px 16px
  border-radius: 24px
  display: flex
  flex-direction: column
  justify-content: space-between
  background-color: var(--color-grey-95)

  &.interactive
    cursor: pointer
    transition: 0.2s ease-in-out

.category
  @include fonts.font-m

.counter
  @include fonts.font-h1
