.installmentPlanFAQParagraph
  & + &
    margin-top: 24px
    padding-top: 24px
    border-top: 1px solid var(--color-black-12)

.installmentPlanFAQParagraphTitle
  display: flex
  justify-content: space-between
  align-items: center
  font-weight: bold
  cursor: pointer

.installmentPlanFAQParagraphIcon
  color: #04CD3E

.installmentPlanFAQParagraphContent
  margin-top: 16px
