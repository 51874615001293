@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.featureTileInfoName
  @include fonts.font-h3
  overflow-wrap: anywhere

  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
  padding: 3px 0px

.smallFixed
  @include fonts.font-h5
