@use '~_css/ds-2023/fonts' as fonts

.cardInfoName
  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2

  &.l
    @include fonts.font-l

  &.ml
    @include fonts.font-ml
