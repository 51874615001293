.headerRoof
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px

  margin-left: var(--page-side-paddings)
  margin-right: var(--page-side-paddings)

.right
  display: flex
  align-items: center
  gap: 6px

.clarificationPopupContainer
  position: absolute
  z-index: 100
  top: calc(100% + 16px)
  left: 0
  right: 0
