.cookieBanner.cookieBanner
  position: fixed
  bottom: 5%
  left: 16px
  right: 16px
  margin: 0px auto
  max-width: 620px
  z-index: 1000
  cursor: auto

.link
  text-decoration: underline
  text-underline-offset: 4px
  text-decoration-color: var(--color-grey-59)
  text-decoration-thickness: 1px
