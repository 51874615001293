@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.checkbox
  padding: 16px var(--page-side-paddings)
  contain-intrinsic-height: 56px

  & + &::before
    content: ''
    height: 1px
    position: absolute
    top: 0
    left: var(--page-side-paddings)
    right: var(--page-side-paddings)
    background-color: var(--color-grey-92)

.top
  position: sticky
  top: 0px
  z-index: 1
  display: flex
  flex-direction: column
  padding: 0px 12px 12px
  background-color: white
  gap: 20px

  &::after
    content: ''
    display: flex
    position: absolute
    left: 0
    right: 0
    top: 100%
    height: 24px
    background: linear-gradient(0, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)

.selected
  display: flex
  flex-wrap: wrap
  gap: 12px

  &:empty
    display: none
