@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.quickSearchEmptyState
  display: flex
  align-items: center
  justify-content: center
  color: var(--color-grey-45)
  height: 408px
  background-color: white
  border-radius: 24px
  text-align: center
