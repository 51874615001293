@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.confirmationPopup
  display: flex
  flex-direction: column
  background: white
  border-radius: 32px
  padding: 40px 60px
  gap: 28px

.buttons
  display: flex
  gap: 6px
