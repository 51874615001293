@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.embededKassaWidgetGrid
  display: grid
  grid-gap: 8px
  padding: 0

  @include mixins.on-narrow
    grid-template-columns: repeat(4, 1fr)

    & > *:nth-child(4)[data-session]:last-of-type
      & ~ *
        display: none

    & > *:nth-child(4)[data-session]:not(:last-of-type)
      display: none

      & ~ *[data-session]
        display: none

  @include mixins.on-normal-wide
    grid-template-columns: repeat(5, 1fr)

    & > *:nth-child(5)[data-session]:last-of-type
      & ~ *
        display: none

    & > *:nth-child(5)[data-session]:not(:last-of-type)
      display: none

      & ~ *[data-session]
        display: none

.fallback
  @include fonts.font-h3
