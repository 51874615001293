@use '~_css/ds-2023/fonts' as fonts

.review
  display: flex
  gap: 24px
  position: relative
  align-items: flex-start

.link
  position: relative

.image
  flex-shrink: 0

  &.logo
    height: 64px
    width: 64px

  &.square
    width: 80px
    height: 80px
    border-radius: 10px

  &.poster
    width: 80px
    height: 120px
    border-radius: 10px

.afishaReviewBadge
  position: absolute
  top: -4px
  right: -4px

.info
  flex-grow: 1
  display: flex
  flex-direction: column
  gap: 16px

.top
  display: flex
  align-items: center
  gap: 16px

.author
  .authorName
    @include fonts.font-m-bold

  .authorCounters
    @include fonts.font-s
    margin-top: 2px
    color: var(--color-grey-59)

.date
  @include fonts.font-s
  color: var(--color-grey-59)
  margin-left: auto

.data
  display: flex
  flex-direction: column
  gap: 4px

.verdict
  @include fonts.font-ml
  white-space: pre-wrap
  font-weight: bold

.text
  @include fonts.font-m-paragraph
  white-space: pre-wrap
  overflow-wrap: anywhere

.emotions
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin-top: 16px

.bottom
  display: flex
  gap: 20px

.dropdown
  padding: 4px
