@use '~_css/ds-2023/fonts' as fonts

.materialName
  @include fonts.font-ml
  margin-top: 4px
  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
  color: black
