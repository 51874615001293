@use '~_css/ds-2023/fonts' as fonts

.red
  color: var(--color-red)

.pageTitle
  @include fonts.font-h3

.pageMeta
  @include fonts.font-s
  color: var(--color-grey-45)

.episodeName
  @include fonts.font-ml

.episodeMeta
  @include fonts.font-s
  color: var(--color-grey-59)
  margin-top: 4px
