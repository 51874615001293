@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/functions' as functions

.pageBlockNormalWide
  padding-left: var(--page-side-paddings)
  padding-right: var(--page-side-paddings)
  box-sizing: border-box
  margin-left: auto
  margin-right: auto

  min-width: var(--page-min-size-outer)

  @include mixins.on-wide
    max-width: var(--page-max-size-outer)
    width: 100%

  .content
    display: flex
    flex-direction: column

  .side
    display: flex
    flex-direction: column
    flex-shrink: 0

  .content:not(:first-child:last-child)
    @include mixins.on-narrow-normal
      width: var(--page-min-size-inner)

    @include mixins.on-wide
      width: functions.columns("var(--page-columns-count) - 1")

    & + .side
      padding-left: var(--page-column-gap)
      margin-left: auto
      width: var(--page-column)

      @include mixins.on-narrow-normal
        display: none

  .content:first-child:last-child
    width: var(--page-min-size-inner)
