@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/mixins' as mixins

@font-face
  font-family: 'Suisse'
  src: url('~_css/fonts/SuisseIntl-Regular.woff2') format('woff2')
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Suisse'
  src: url('~_css/fonts/SuisseIntl-Medium.woff2') format('woff2')
  font-weight: bold
  font-style: normal
  font-display: swap

@font-face
  font-family: 'Afisha Display'
  src: url('~_css/fonts/AfishaDisplay-Medium.woff2') format('woff2')
  font-weight: normal
  font-style: normal
  font-display: block

html
  @include fonts.font-m
  font-family: 'Suisse', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased

body
  position: relative

#root
  display: flex
  flex-direction: column
  min-width: var(--min-page-size)
