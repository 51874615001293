@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.ad
  display: flex
  align-items: center
  justify-items: center
  flex-shrink: 0
  margin-left: auto
  margin-right: auto
  max-width: calc(100vw - var(--page-side-paddings) * 2)

.adFallback
  display: flex
  align-items: center
  justify-items: center
  flex-shrink: 0

  &:not(.overflow)
    overflow: hidden
