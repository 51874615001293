@use '~_css/ds-2023/fonts' as fonts

$inside-paddings: 20px

.list
  display: flex
  flex-direction: column
  gap: 24px
  @include fonts.font-s
  margin-top: 60px
  counter-reset: certDescCounter

.framed
  padding: $inside-paddings
  background-color: var(--color-grey-95)
  border-radius: 24px

.listItem
  display: flex
  counter-increment: certDescCounter
  &::before
    content: counter(certDescCounter)
    width: calc(clamp(82px, 25%, 234px) - 16px * 2)
    @include fonts.font-h2
    @extend .framed
    padding: $inside-paddings 18px

.content
  display: flex
  flex-direction: column
  flex-basis: 100%
  @extend .framed

.appInfo
  @extend .content
  flex-direction: column
  gap: 20px
  padding-bottom: 0
  & > img
    width: 72%
    place-self: center

.conditionsList
  display: flex
  flex-direction: column
  gap: 12px
  list-style-type: disc
  margin-left: 15px
  & > li
    list-style-position: outside
    padding: 0

.impressions
  @extend .content
  gap: 11px
  @include fonts.font-h5
  padding: $inside-paddings 0 0 0
  & > span
    padding-left: $inside-paddings
  & > img
    width: 100%
    padding-bottom: 5px

.links
  display: flex
  gap: 8px
  flex-wrap: wrap
  margin: 0 -7px

.link
  & > img
    display: block

.usageLink
  color: var(--color-grey-45)
  margin-top: 20px
  @include fonts.font-custom-link-10-13
  text-decoration: underline
