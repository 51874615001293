@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts
@use '~_css/ds-2023/buttons' as buttons

.captcha
  display: flex
  flex-direction: column
  align-items: center
  background-color: white
  box-sizing: border-box

  @include mixins.on-desktop-screen
    margin: auto
    border-radius: 32px
    padding: 24px
    width: 424px

  @include mixins.on-mobile-screen
    width: 100%
    padding: 16px 16px 22px
    margin-top: auto
    border-radius: 24px 24px 0 0

.title
  align-self: flex-start
  color: var(--color-grey-40)

  @include mixins.on-desktop-screen
    @include fonts.font-m

  @include mixins.on-mobile-screen
    @include fonts.font-s

.imageContainer
  overflow: hidden
  border-radius: 20px
  width: 100%
  height: 192px

  @include mixins.on-desktop-screen
    margin-top: 20px

  @include mixins.on-mobile-screen
    margin-top: 16px

.image
  width: 100%
  height: 192px

.refresh
  @include buttons.default
  @include buttons.outline
  @include fonts.font-s
  margin-top: 12px
  padding: 7px 10px
  border-radius: 18px

.controls
  display: flex
  gap: 8px
  margin-top: 8px
  width: 100%

.input
  height: 56px
  width: 100%
  padding: 0 16px

.error
  @include fonts.font-s
  margin-top: 6px
  color: var(--color-red)
  transition: opacity 0.2s ease-in-out

  &.visible
    opacity: 1

  &:not(.visible)
    opacity: 0
