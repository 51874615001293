button.pushkincard
  &[aria-label="Пушкинская карта"]
    position: relative
    overflow: hidden
    padding-left: 38px

    img
      position: absolute
      left: -1px
      bottom: -1px
