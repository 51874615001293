@use '~_css/ds-2023/fonts' as fonts

.directorLink
  display: flex
  align-items: center
  gap: 16px

.directorLinkInfo
  display: flex
  flex-direction: column
  gap: 2px

.directorLinkMetaInfo
  color: var(--color-grey-59)
