@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.sberPrimeOneMomentPopup
  display: flex
  flex-direction: column
  max-width: 420px
  box-sizing: border-box
  padding: 32px 48px
  border-radius: 20px
  background: white
