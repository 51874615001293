.list
  display: flex
  flex-direction: column
  gap: 12px

.card
  display: flex
  flex-direction: column
  gap: 40

.poster
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 36px 12px
