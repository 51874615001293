@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.userReviewFormInputs
  display: flex
  flex-direction: column
  background: white
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1)
  border-radius: 24px
  box-sizing: border-box
  position: relative
  overflow: hidden
  min-height: 142px

  &.grown
    & > .text
      padding-top: 0

    & > .verdict
      height: calc(36px + 20px)
      min-height: calc(36px + 20px)
      padding-bottom: 13px
      padding-top: 20px

    & > .delimiter
      opacity: 1
      margin-bottom: 16px

  &:not(.grown)
    & > .text
      padding-top: 20px

    & > .verdict
      // я не конченный, просто там переопределить инлайн высоту надо
      height: 0 !important
      min-height: 0px
      padding-top: 0

    & > .delimiter
      margin-bottom: 0
      opacity: 0

.verdict,
.text
  @include fonts.font-m-paragraph
  border: none
  padding: 0
  outline: none
  resize: none
  background: transparent
  flex-shrink: 0
  overflow: hidden

.verdict
  transition: height 0.2s, min-height 0.2s, padding-top 0.2s, padding-bottom 0.2s
  box-sizing: border-box

  padding-left: 24px
  padding-right: 24px

.text
  transition: padding-top 0.2s
  box-sizing: border-box
  flex-grow: 1

  padding-bottom: 20px
  padding-left: 24px
  padding-right: 80px

.delimiter
  all: unset
  transition: opacity 0.2s, margin-bottom 0.2s
  position: relative

  &::before
    position: absolute
    left: 24px
    right: 24px
    background-color: var(--color-grey-92)
    height: 1px
    content: ''

.submit
  position: absolute
  bottom: 24px
  right: 24px
  transition: opacity 0.2s

  &:not(.visible)
    opacity: 0
    pointer-events: none
