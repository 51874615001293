.installmentPlanHowItWorks
  display: flex
  flex-direction: column
  align-items: center

  .grid
    display: grid
    grid-template-columns: 280px 280px 280px
    grid-template-rows: 280px 280px 220px
    grid-gap: 32px
