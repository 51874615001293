@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.squareItemTop
  position: absolute
  top: 0
  left: 0

  display: flex
  align-items: center
  gap: 2px
