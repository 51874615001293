@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

@keyframes fade-in
  0%
    opacity: 0

  100%
    opacity: 1

.sizeTransition
  transition: height 0.3s
  will-change: height

.container
  animation: fade-in 0.5s forwards
  will-change: opacity
