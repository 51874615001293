@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.upperMeta
  border-collapse: collapse
  border-spacing: 0
  th
    text-align: unset
    vertical-align: unset
    font-weight: unset

.objectMetaList
  @include fonts.font-s
  display: flex
  flex-direction: column
  gap: 10px
