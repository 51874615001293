.container
  width: 100%
  height: 100%
  background-color: var(--color-grey-97)
  display: flex
  flex-direction: column
  align-items: center
  padding: 8px 0px 16px
  gap: 8px

.sidebar
  width: 300px

.insteadOfAd
  color: var(--color-grey-59)
  text-align: center
  font-size: 13px
