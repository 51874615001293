.upperMeta
  border-collapse: collapse
  border-spacing: 0
  th
    text-align: unset
    vertical-align: unset
    font-weight: unset

.metaInfoGrid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 24px 16px
