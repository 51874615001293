@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.quickSearchContent
  display: flex
  flex-direction: column
  border-radius: 24px
  background-color: white
  position: relative
  overflow: hidden

.results
  @include mixins.no-scrollbar
  padding: 8px 8px 32px
  overflow: auto

.bottom
  position: relative
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 20px
  z-index: 1

  &::before
    content: ''
    position: absolute
    left: 0
    right: 0
    bottom: 100%
    height: 40px
    background: linear-gradient(0deg, white 0%, transparent)
    pointer-events: none
