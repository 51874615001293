@use '~_css/ds-2023/fonts' as fonts

.AfishaDisplay
  font-family: 'Afisha Display'

.xxl
  @include fonts.font-xxl

.xl
  @include fonts.font-xl

.xl-paragraph
  @include fonts.font-xl-paragraph

.xl-paragraph-bold
  @include fonts.font-xl-paragraph-bold

.xl-numbers
  @include fonts.font-xl-numbers

.l
  @include fonts.font-l

.l-bold
  @include fonts.font-l-bold

.ml
  @include fonts.font-ml

.ml-paragraph
  @include fonts.font-ml-paragraph

.m
  @include fonts.font-m

.m-paragraph
  @include fonts.font-m-paragraph

.m-bold
  @include fonts.font-m-bold

.m-numbers
  @include fonts.font-m-numbers

.m-caps
  @include fonts.font-m-caps

.s
  @include fonts.font-s

.s-numbers
  @include fonts.font-s-numbers

.s-caps
  @include fonts.font-s-caps

.n1
  @include fonts.font-n1

.n2
  @include fonts.font-n2

.n3
  @include fonts.font-n3

.h1
  @include fonts.font-h1

.h2
  @include fonts.font-h2

.h3
  @include fonts.font-h3

.h4
  @include fonts.font-h4

.h5
  @include fonts.font-h5

.custom-session-10-13
  @include fonts.font-custom-session-10-13

.custom-sale-10-13
  @include fonts.font-custom-sale-10-13

.grey-59
  color: var(--color-grey-59)

.grey-45
  color: var(--color-grey-45)
