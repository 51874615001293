.fullscreenContainer
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10000
  overflow-y: auto
  transition: transform 0.3s, opacity 0.3s
  transition-timing-function: cubic-bezier(0.21, -0.57, 0.58, 1.51)
  will-change: transform

  &:not(.isVisible)
    opacity: 0
    transform: scale(0.95)
    pointer-events: none

.centered
  display: flex
  justify-content: center
  align-items: center

.overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1
  background-color: rgba(0, 0, 0, 0.6)

.content
  z-index: 2

  &:not(.toTop):not(.toBottom):not(.toLeft):not(.toRight)
    position: relative

  &.fullheight
    height: 100%

  &.toTop
    position: absolute
    right: 0
    bottom: 0
    left: 0

  &.toBottom
    position: absolute
    top: 0
    right: 0
    left: 0

  &.toLeft
    position: absolute
    top: 0
    right: 0
    bottom: 0

  &.toRight
    position: absolute
    top: 0
    bottom: 0
    left: 0

  &.toCenter
    width: 100%
