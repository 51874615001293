@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.headerMenu
  display: flex

.menuItem.menuItem
  padding-left: 12px
  padding-right: 12px

.daily.daily
  color: #8e00ff
