@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.title
  @include fonts.font-h4
  margin: 16px var(--page-side-paddings) 24px

.resultsCount
  padding-left: 12px
  padding-right: 12px
  font-size: 13px
  color: var(--color-grey-59)

.searchInAllCitiesButton
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 10
  background: linear-gradient(0deg, white calc(100% - 11%), transparent)
  padding: 16px var(--page-side-paddings)
