@use '~_css/ds-2023/mixins' as mixins
@use '~_css/ds-2023/fonts' as fonts

.link
  display: flex
  aspect-ratio: 1/1
  height: inherit
  width: 100%

.content
  display: flex
  justify-content: flex-end
  align-items: flex-end
  top: 6px
  left: 6px
  bottom: 6px
  right: 6px
  gap: 2px

.image
  width: 100%
  height: 100%
