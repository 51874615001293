.searchInput
  width: 100%
  padding: 0 16px 0 calc(16px + 24px + 8px)
  background: 16px/24px no-repeat url(/img/svg/__imported__/i-icon--search-small.svg), white

  &::-webkit-search-cancel-button
    -webkit-appearance: none
    height: 24px
    width: 24px
    background: 0/24px no-repeat url(/img/svg/__imported__/i-icon--x.svg)
    opacity: 0.41
